import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ConfirmUnlockIntentionResolver,
  UseActionsHook,
  UseActionsHookReturnType,
  UseActionsIsLoadingState,
} from 'shared/contexts/CandidateContext/types';
import { CanceledByUserException } from 'shared/exceptions';
import { CANDIDATE_UNLOCK_REASON } from 'types/candidate';
import { getAvailableActions } from 'utils/candidates';
import { useCallMobileByPushNotification } from './useCallMobileByPushNotification';
import { useContact } from './useContact';
import { useDownload } from './useDownload';
import { useHidden } from './useHidden';
import { useLoadNotes } from './useLoadNotes';
import { useMakeInvisible } from './useMakeInvisible';
import { useMatch } from './useMatch';
import { useRefresh } from './useRefresh';
import { useRequestCV } from './useRequestCV';
import { useResendAts } from './useResendAts';
import { useSaveLabels } from './useSaveLabels';
import { useSaveNotes } from './useSaveNotes';
import { useSetActiveJobBoard } from './useSetActiveJobBoard';
import { useShare } from './useShare';
import { useShortlist } from './useShortlist';
import { useUnlock } from './useUnlock';

export const useActions: UseActionsHook = (candidate, { shouldConfirmUnlockIntention = false }) => {
  const candidateActions = getAvailableActions();
  const [isLoading, _setIsLoading] = useState<UseActionsIsLoadingState>(isLoadingInitialState);
  const [cvFile, setCvFile] = useState<any>();
  const [confirmUnlockModalShouldBeVisible, setConfirmUnlockModalShouldBeVisible] = useState(false);
  const confirmUnlockIntentionResolverRef = useRef<ConfirmUnlockIntentionResolver>({
    resolve: () => undefined,
  });

  const availableActions = useMemo(() => {
    if (!candidate?.status || !candidateActions) {
      return {
        actions: [],
        extraOptions: {},
      };
    }

    return (
      candidateActions[candidate.status] ?? {
        actions: [],
        extraOptions: {},
      }
    );
  }, [candidateActions, candidate?.status]);

  const setIsLoading = useCallback((newLoadingState: Partial<UseActionsIsLoadingState>) => {
    _setIsLoading((oldLoadingState) => ({ ...oldLoadingState, ...newLoadingState }));
  }, []);

  const loadDependenciesFor = useCallback(
    (key: keyof UseActionsIsLoadingState, options?: { registerWerknlActivity?: boolean }) => ({
      candidate,
      setIsLoading: (isLoading: boolean) => setIsLoading({ [key]: isLoading }),
      registerWerknlActivity: options?.registerWerknlActivity,
    }),
    [candidate],
  );

  const setActiveJobBoard = useSetActiveJobBoard(loadDependenciesFor('setActiveJobBoard'));
  const refresh = useRefresh(loadDependenciesFor('refresh'));
  const unlock = useUnlock(loadDependenciesFor('unlock', { registerWerknlActivity: true }));
  const shortlist = useShortlist(loadDependenciesFor('shortlist', { registerWerknlActivity: true }));
  const contact = useContact(loadDependenciesFor('contact', { registerWerknlActivity: true }));
  const match = useMatch(loadDependenciesFor('approve', { registerWerknlActivity: true }));
  const hide = useHidden(loadDependenciesFor('reject', { registerWerknlActivity: true }));
  const makeInvisible = useMakeInvisible(loadDependenciesFor('makeInvisible'));
  const saveLabels = useSaveLabels(loadDependenciesFor('saveLabels', { registerWerknlActivity: true }));
  const loadNotes = useLoadNotes(loadDependenciesFor('loadNotes'));
  const saveNotes = useSaveNotes(loadDependenciesFor('saveNotes', { registerWerknlActivity: true }));
  const share = useShare(loadDependenciesFor('share', { registerWerknlActivity: true }));
  const download = useDownload(loadDependenciesFor('download', { registerWerknlActivity: true }));
  const resendAts = useResendAts(loadDependenciesFor('resendAts', { registerWerknlActivity: true }));
  const requestCV = useRequestCV(loadDependenciesFor('requestCV', { registerWerknlActivity: true }));
  const callMobileByPushNotification = useCallMobileByPushNotification(
    loadDependenciesFor('callMobileByPushNotification'),
  );

  const confirmUnlockIntention = useCallback(async (): Promise<boolean> => {
    if (!shouldConfirmUnlockIntention) return Promise.resolve(true);

    setConfirmUnlockModalShouldBeVisible(true);
    return new Promise((res, rej) => {
      confirmUnlockIntentionResolverRef.current = {
        resolve: (v) => {
          setConfirmUnlockModalShouldBeVisible(false);

          if (!v) {
            rej(new CanceledByUserException());
          }

          res(v);
        },
      };
    });
  }, [candidate]);

  // resendAts;

  const onRefresh: Actions['onRefresh'] = async ({ requestDetail } = {}) => refresh({ requestDetail });

  const onUnlock: Actions['onUnlock'] = async (params) => {
    if (candidate?.isUnlocked) {
      return true;
    }

    await confirmUnlockIntention();
    /// TODO WERKZOEKEN: Remove this after release new jobboard
    if (candidate?.activeJobBoard === 'monsterboard_v2')
      // if (candidate?.activeJobBoard === 'monsterboard_v2' || candidate?.activeJobBoard === 'werkzoeken')
      await onRefresh({ requestDetail: true });
    await unlock(params);

    return true;
  };

  const onDownload: Actions['onDownload'] = async () => {
    await onUnlock({ reason: CANDIDATE_UNLOCK_REASON.CV });
    await download();
  };

  const onRequestCV: Actions['onRequestCV'] = async () => {
    await onUnlock({ reason: CANDIDATE_UNLOCK_REASON.CV });
    setCvFile(await requestCV());
  };

  const onSetActiveJobBoard: Actions['onSetActiveJobBoard'] = async (jobBoardId) => setActiveJobBoard({ jobBoardId });
  const onLoadNotes: Actions['onLoadNotes'] = async () => loadNotes();
  const onSaveNotes: Actions['onSaveNotes'] = async (notes) => saveNotes({ notes });
  const onSaveLabels: Actions['onSaveLabels'] = async (labels, options) => saveLabels({ labels, options });
  const onShortlist: Actions['onShortlist'] = async (campaignId) => shortlist({ campaignId });
  const onContact: Actions['onContact'] = async (data, type, extras, options) =>
    contact({ data, sendMethod: type, extras, options });
  const onApprove: Actions['onApprove'] = async (campaignId) => match({ campaignId });
  const onReject: Actions['onReject'] = async (labels) => hide({ reasons: labels });
  const onMakeInvisible: Actions['onMakeInvisible'] = async (params) => makeInvisible(params);
  const onShare: Actions['onShare'] = async () => share();
  const onResendAts: Actions['onResendAts'] = async () => resendAts();
  const onCallMobileByPushNotification: Actions['onCallMobileByPushNotification'] = async () =>
    callMobileByPushNotification();

  return useMemo(
    () => ({
      isLoading,
      onShare,
      onUnlock,
      onSaveLabels,
      onApprove,
      onContact,
      onMakeInvisible,
      onResendAts,
      onReject,
      onShortlist,
      onRefresh,
      onDownload,
      onRequestCV,
      onSaveNotes,
      onLoadNotes,
      onSetActiveJobBoard,
      onCallMobileByPushNotification,

      confirmUnlockModalShouldBeVisible,
      confirmUnlockIntentionResolverRef,

      availableActions,
      cvFile,
    }),
    [candidate, isLoading, onSaveNotes, confirmUnlockModalShouldBeVisible, availableActions, cvFile],
  );
};

export const isLoadingInitialState: UseActionsIsLoadingState = {
  share: false,
  unlock: false,
  saveLabels: false,
  approve: false,
  contact: false,
  makeInvisible: false,
  resendAts: false,
  reject: false,
  shortlist: false,
  refresh: false,
  download: false,
  requestCV: false,
  setActiveJobBoard: false,
  saveNotes: false,
  loadNotes: false,
  callMobileByPushNotification: false,
};

type Actions = UseActionsHookReturnType;
