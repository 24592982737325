import { useQuery } from '@tanstack/react-query';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { Candidate } from 'model';
import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { FiltersContext } from 'shared/contexts/SearchContext';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { CandidateStatus } from 'types/candidate';
import { QueryKey } from 'types/query';
import { useContextSelector } from 'use-context-selector';
import { extractExperiences } from './extractExperiences';
import { extractLastRefresh } from './extractLastRefresh';
import { extractLocation } from './extractLocation';
import { extractPreferences } from './extractPreferences';
import {
  CandidateDetailsTab,
  SearchV3CandidateResponse,
  SearchV3CandidateStatefulResponse,
  SearchV3CandidateUIState,
  Searchv3CandidateHandler,
} from './types';
import { useCandidateActivityLog } from './useCandidateActivityLog';
import { useCandidateHistory } from './useCandidateHistory';

export const useSearchV3Candidate: Searchv3CandidateHandler = (
  _candidate,
  { actions, config = initialState.config },
) => {
  const { dictionary, language } = useContext(LocalizationContext);
  const pageContext = usePageContext();
  const filterLocations = useContextSelector(FiltersContext, (state) => state.filters.locations);
  const selectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.selectedCandidate);
  const setSelectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.setSelectedCandidate);
  const setSelectedCandidateConfig = useContextSelector(
    SelectedCampaignContext,
    (state) => state.setSelectedCandidateConfig,
  );

  const isSelected =
    _candidate?.key === selectedCandidate?.key && _candidate !== undefined && selectedCandidate !== undefined;

  useQuery(
    [QueryKey.selectedCandidateLoadNotes, { es_person_id: _candidate?.es_person_id, status: _candidate?.status }],
    () => {
      if (!_candidate) return;

      return actions.onLoadNotes();
    },
    {
      enabled:
        isSelected || (_candidate && (pageContext === PageContext.Shared || pageContext === PageContext.Candidate)),
    },
  );

  const candidateActivityLog = useCandidateActivityLog(_candidate, language);
  const candidateHistory = useCandidateHistory(_candidate, language);

  const candidate = useMemo<SearchV3CandidateResponse>(() => {
    if (!_candidate) return initialState.candidate;

    const { name, notes, es_person_id } = _candidate;

    const history = candidateHistory.data;

    const location = extractLocation(_candidate, filterLocations);

    const lastRefresh = extractLastRefresh(_candidate.lastRefresh, language);

    const experiences = extractExperiences(_candidate.experiences, dictionary);

    const preferences = extractPreferences(_candidate.preferences, dictionary);

    return {
      key: _candidate.key,
      name,
      notes,
      es_person_id,
      location,
      isSelected,
      lastRefresh,
      experiences,
      preferences,
      history,

      select: (_c) => {
        const c = initialState.config;

        setSelectedCandidateConfig({
          ...c,
          candidateDetails: { ...c.candidateDetails, ...(_c?.candidateDetails ?? {}) },
        });
        setSelectedCandidate(_candidate);
      },
      bindMatchesAndGetJobboards: (_matches) => {
        const matches = _matches[_candidate.es_person_id];

        if (_candidate.platformMatches.length === 0) {
          _candidate.bindCandidateMatches(matches);
        }

        return _candidate.jobBoards;
      },
    };
  }, [_candidate, filterLocations, isSelected, candidateHistory]);

  const candidateUIState = useMemo<SearchV3CandidateUIState>(() => {
    if (!_candidate) return initialState.candidateUIState;

    const status = getCandidateStatus(_candidate.status);

    return {
      isLoading: false,
      isSelected: _candidate.key === selectedCandidate?.key,
      status,
    };
  }, [_candidate, selectedCandidate?.key]);

  return useMemo(
    () => ({ config, candidate, candidateUIState, candidateActivityLog }),
    [config.candidateDetails.initialTab, candidate, candidateUIState, candidateActivityLog],
  );
};

export const initialState: SearchV3CandidateStatefulResponse = {
  config: { candidateDetails: { initialTab: CandidateDetailsTab.CV } },

  candidateUIState: {
    isLoading: true,
    isSelected: false,
    status: CandidateStatus.New,
  },

  candidate: {
    key: '',
    name: '',
    notes: [],
    es_person_id: '',
    experiences: [],
    preferences: [],
    history: [],
    select: () => {
      throw Error('not implemented');
    },
    bindMatchesAndGetJobboards: () => {
      throw Error('not implemented');
    },
  },

  candidateActivityLog: {
    data: [],
    isLoading: true,
  },
};

const getCandidateStatus = (status: Candidate['status']): CandidateStatus => {
  if (status === 'CONTACTED') return CandidateStatus.Contacted;
  if (status === 'HIDDEN') return CandidateStatus.Hidden;
  if (status === 'INVISIBLE') return undefined as any;
  if (status === 'MATCHED') return CandidateStatus.Matched;
  if (status === 'NEW') return CandidateStatus.New;
  if (status === 'SHORTLISTED') return CandidateStatus.Shortlisted;

  return CandidateStatus.Hidden;
};
