import { MODAL_TRANSITION_DURATION_MS, useStyles } from './styles';
import { ClassNames } from '@emotion/react';
import { X } from 'components/ui/atoms/icons';
import { default as ReactModal } from 'react-modal';

import type { ModalProps } from './types';

const Modal = ({
  overlayCss,
  className,
  children,
  visible = false,
  sideModal = false,
  onRequestClose,
  hideCloseButton = false,
  overlayRef,
}: ModalProps) => {
  const classes = useStyles({ sideModal });

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ReactModal
          shouldCloseOnOverlayClick
          isOpen={visible}
          onRequestClose={onRequestClose}
          bodyOpenClassName={css(classes.body)}
          overlayClassName={cx(css(classes.overlay), sideModal && css(classes.sideModal), css(overlayCss))}
          overlayRef={overlayRef}
          className={cx(css(classes.content), className)}
          closeTimeoutMS={MODAL_TRANSITION_DURATION_MS}
          preventScroll>
          {!hideCloseButton && (
            <span onClick={onRequestClose} css={classes.closeModalIcon}>
              <X size={14} color={(c) => c.monochrome[70]} />
            </span>
          )}

          {children}
        </ReactModal>
      )}
    </ClassNames>
  );
};

export default Modal;
