import type {
  FeatureToggle,
  LoginHandler,
  UpdateCurrentUserHandler,
  UserCredit,
  UserResponse,
} from 'clients/UserClient/types';
import type { CvSource } from 'clients/Admin/TenantClient/types';

export type EmailSettingsProps = {
  enabled: boolean;
  frequency: number;
};

export type JobBoardSettingsType = {
  id?: number;
  never_ask_before_spending_monster_inventory_credit: boolean;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // never_ask_before_spending_werkzoeken_credit: boolean;
};

export type UserContextProps = {
  isLoggedIn: boolean;
  login?: LoginHandler;
  logout: () => void;
  impersonate: (token: string) => Promise<void>;
  currentUser?: UserResponse;
  tenant?: any; //FIXME
  updateCurrentUser?: UpdateCurrentUserHandler;
  userCredits?: UserCredit;
  hasMonsterboardV2License?: boolean;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // hasWerkzoekenLicense?: boolean;
  hasWerknlBoardLicense?: boolean;
  companyType?: any; //FIXME
  atsList?: any; //FIXME
  sourceList?: any; //FIXME
  usedCredits?: number;
  availableCredits?: number;
  userEmailSettings?: any;
  updateUserEmailSettingsProfile?: any;
  userJobBoardSettings?: JobBoardSettingsType;
  updateUserJobBoardSettingsProfile?: (values: JobBoardSettingsType, id?: number) => Promise<boolean>;
  hasATSSource?: any;
  askBeforeSpendingMonsterInventoryCredit?: boolean;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // askBeforeSpendingWerkzoekenCredit?: boolean;
  featureTogglesIsLoading: boolean;
  availableJobBoardsIsLoading: boolean;
  tenantCVSources: CvSource[];
  tenantCvSourcesIsLoading: boolean;
  mailSignOff: { fullName?: string; phoneNumber?: string };
  isFeatureToggleEnabled: (name: string) => boolean;
  allowOutreach: (params: { tenant_id: number; allow: boolean }) => Promise<FeatureToggle>;
  isFirstUserLoads: boolean;
  isInvalidToken: boolean;
  hasLinkedinFreeInviteRemaining: boolean;
};

export type UserContextProviderProps = {
  children: React.ReactNode;
};

export const WERKNL_ACTIONS = 'WERKNL_ACTIONS';
export const WERKNL_LAYOUT_CHOSEN = 'WERKNL_LAYOUT_CHOSEN';
