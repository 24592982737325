import { Copy_01, DotsVertical, Edit_05, PlayCircle, Trash_01, XCircle } from '@recruitrobin/robin-theme/web-icons';
import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { Animated, Column, MultiFloatingButton, Typography } from 'components/ui/atoms';
import { LoaderSpinner1 } from 'components/ui/atoms/icons/animated';
import { CampaignNotificationPill, Skeleton } from 'components/ui/molecules';
import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './CampaignListItem.styles';
import { CampaignListItemProps } from './CampaignListItem.types';
import { useContainer } from './CampaignListItem.useContainer';
import CampaignListItemActionOption from './CampaignListItemActionOption';
import { ActionNames } from './CampaignListItemActionOption.types';

const CampaignListItem = (props: CampaignListItemProps) => {
  const {
    isLoading,
    isLoadingAction,
    campaign,
    status,
    isSelected,
    handleOpenCampaign,
    handleChangeOnwer,
    handleDelete,
    handleDuplicate,
    handleToggleStatus,
  } = useContainer(props);
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();
  const [lastIsLoading, setLastIsLoading] = useState(isLoading);

  useEffect(() => {
    if (lastIsLoading !== isLoading) {
      setLastIsLoading(true);
    }
  }, [isLoading]);

  return (
    <div
      key={isLoading ? `index-${props.index}` : `${campaign.id}`}
      css={[classes.root, isLoading && classes.isLoading, isSelected && classes.selected]}
      onClick={() => handleOpenCampaign()}
      style={props.style}>
      <Column gap={0} css={classes.campaignName}>
        <AnimatePresence initial={lastIsLoading}>
          {isLoading ? (
            <Animated.FadeInOut key="loading">
              <Skeleton variant="text" width={150} textVariant="body.long" />
            </Animated.FadeInOut>
          ) : (
            <Animated.FadeInOut key="loaded">
              <Typography
                variant={(v) => v.body.short}
                colorv2={(c) => c.neutral.variant[80]}
                css={classes.textEllipsis}>
                {campaign.name}
              </Typography>
            </Animated.FadeInOut>
          )}
        </AnimatePresence>
        <AnimatePresence initial={lastIsLoading}>
          {isLoading ? (
            <Animated.FadeInOut key="loading">
              <Skeleton variant="text" width={100} textVariant="body.long" />
            </Animated.FadeInOut>
          ) : (
            <Animated.FadeInOut key="loaded">
              <Typography variant={(v) => v.supporting.helper} colorv2={(c) => c.neutral.variant[60]}>
                {campaign.full_owner_name}
              </Typography>
            </Animated.FadeInOut>
          )}
        </AnimatePresence>
      </Column>

      {!isLoading && (
        <div css={classes.cardInfo}>
          <CampaignNotificationPill
            newCandidates={campaign.new_candidates_count}
            // newMessages={1} TODO insert here the indicator of new messages
            onClickNewCandidates={() => handleOpenCampaign()}
            onClickNewMessages={() => handleOpenCampaign('CONTACTED')}
          />
          <div css={classes.multiFloatingRoot} onClick={(e) => e.stopPropagation()} className="multiFloatingContainer">
            <MultiFloatingButton
              buttonChildren={<DotsVertical size={16} />}
              menuChildren={() => (
                <div>
                  {[
                    {
                      name: ActionNames.duplicate,
                      onClick: handleDuplicate,
                      Icon: Copy_01,
                      label: dictionary.duplicate,
                      isLoading: isLoadingAction.duplicate,
                    },
                    {
                      name: ActionNames.toggleStatus,
                      onClick: handleToggleStatus,
                      Icon: status === CampaignOverviewStatus.active ? XCircle : PlayCircle,
                      label: status === CampaignOverviewStatus.active ? dictionary.deactivate : dictionary.activate,
                      isLoading: isLoadingAction.toggleStatus,
                    },
                    {
                      name: ActionNames.changeOwner,
                      Icon: Edit_05,
                      label: dictionary.changeOwner,
                      isLoading: isLoadingAction.changeOwner,
                    },
                    {
                      name: ActionNames.delete,
                      onClick: handleDelete,
                      Icon: Trash_01,
                      label: dictionary.delete,
                      isLoading: isLoadingAction.delete,
                    },
                  ].map(({ onClick, Icon, label, isLoading, name }) => (
                    <CampaignListItemActionOption
                      name={name}
                      key={name}
                      onClick={onClick}
                      renderIcon={(isLoading) =>
                        isLoading ? (
                          <LoaderSpinner1 size={16} />
                        ) : (
                          <Icon
                            size={16}
                            color={(c) => (name === ActionNames.delete ? c.tertiary.red[50] : c.neutral.variant[70])}
                          />
                        )
                      }
                      label={label}
                      isLoading={isLoading}
                      campaign={campaign}
                      handleChangeOwner={handleChangeOnwer}
                    />
                  ))}
                </div>
              )}
              tooltipArrow={true}
              tooltipChildren={<Typography variant="supporting.helper">{dictionary.more}</Typography>}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignListItem;
