import { useState, useRef, useEffect, useContext } from 'react';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import type { LinkedinMessagingResponse } from './types';
import { useQuery } from '@tanstack/react-query';
import { testUrls, useBrowserExtension } from './useBrowserExtension';
import { queryClient } from 'config/queryClient';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { encodeBase64 } from 'helpers/base64';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';

const REFETCH_INTERVAL = 30 * 1000; // 30 seconds

export function useUnreadLinkedInMessages() {
  const { linkedinStatus } = useBrowserExtension();
  const { currentUser } = useContext(UserContext);

  const invalidationCountRef = useRef(0);
  const [shouldRunCountUnreadQuery, setShouldRunCountUnreadQuery] = useState(true);

  const { data: fsdProfileId } = useQuery(
    ['linkedInFsdProfileId'],
    async () => {
      const responseLinkedin = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: testUrls.linkedin,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
      });

      const regex = /urn:li:fsd_profile:([^",]+)/;
      const match = responseLinkedin.body.match(regex);
      const fsdProfileId: string = match ? match[1] : null;

      if (!fsdProfileId) {
        throw new Error('fsd_profile not found');
      }

      return fsdProfileId;
    },
    {
      enabled: linkedinStatus === 'Logged In',
    },
  );

  useQuery(
    ['countUnreadLinkedInMessages', fsdProfileId],
    async () => {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: `https://www.linkedin.com/voyager/api/voyagerMessagingGraphQL/graphql?queryId=messengerConversations.0fd27f53c2a17c81a3c2370c94ab3068&variables=(categories:List(INBOX),mailboxUrn:urn%3Ali%3Afsd_profile%3A${fsdProfileId},read:false)`,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
      });

      return response;
    },
    {
      enabled: !!fsdProfileId && linkedinStatus === 'Logged In' && shouldRunCountUnreadQuery,
      refetchInterval: REFETCH_INTERVAL,
      onSuccess: async (data) => {
        const jsonData: LinkedinMessagingResponse = JSON.parse(data.body);

        if (jsonData.errors?.some((error) => error.extensions.status === 403)) {
          if (invalidationCountRef.current < 3) {
            invalidationCountRef.current += 1;
            queryClient.invalidateQueries(['linkedInFsdProfileId']);
          } else {
            setShouldRunCountUnreadQuery(false);
          }
          throw new Error('403 Forbidden');
        }

        const unreads = jsonData.data.messengerConversationsBySyncToken.elements.filter(
          (element) => !element.read && !element.categories.includes('ARCHIVE'),
        );

        const onlyUnreadsFromCandidates = unreads.filter((unread) =>
          unread.conversationParticipants.every((participant) => participant.hostIdentityUrn.includes('fsd_profile')),
        );

        const messages = onlyUnreadsFromCandidates.map((message) => {
          const currentUserId = `urn:li:fsd_profile:${fsdProfileId}`;

          const [candidate] = message.conversationParticipants.filter(
            (participant) => participant.hostIdentityUrn !== currentUserId,
          );

          const candidateId = candidate.hostIdentityUrn.split(':fsd_profile:')[1];
          const esPersonId = `cvt-${candidateId}`;

          return {
            es_person_id: esPersonId,
            message_id: String(message.createdAt),
            thread_id: encodeBase64(`${currentUser?.id}-${esPersonId}`),
          };
        });

        notificationsClient.saveLinkedinMessagesNotifications({ messages });
      },
    },
  );

  useEffect(() => {
    invalidationCountRef.current = 0;
    setShouldRunCountUnreadQuery(true);
  }, [linkedinStatus, fsdProfileId]);
}
