import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    display: flex;
    width: 650px;
    height: 100vh;
    overflow: hidden;

    background: white;
  `,
  fadeInOut: css`
    display: flex;
    width: 100%;
    height: 100%;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
