import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import type { ModalStyleStates } from './types';
import { styleUtils } from 'utils';

export const MODAL_TRANSITION_DURATION_MS = 225;

export const SIDE_MODAL_WIDTH = 536;

const styles = {
  body: () =>
    css({
      overflow: 'hidden',
    }),
  overlay: ({ props: { sideModal } }) =>
    css({
      zIndex: 3,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: styleUtils.hexToRgba('#000000', 0.5),
      opacity: 0,
      transition: styleUtils.generateTransitionStyles(['opacity'], `${MODAL_TRANSITION_DURATION_MS}ms`),
      '&.ReactModal__Overlay--after-open': {
        opacity: 1,
      },
      '&.ReactModal__Overlay--before-close': {
        opacity: 0,
      },
      ...(sideModal && {
        background: 'transparent',
        overflow: 'hidden',
        '&.ReactModal__Overlay--after-open, &.ReactModal__Overlay--before-close': {
          opacity: 1,
        },
      }),
    }),
  content: ({ theme, props: { sideModal } }) =>
    css({
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[0],
      background: theme.colors.neutral.variant[0],
      overflow: 'auto',
      borderRadius: 16,
      outline: 'none',
      padding: '24px 30px',
      boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03), 0px 2px 3px 0px rgba(96, 113, 128, 0.10)',
      ...(sideModal && {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        transform: 'unset',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.neutral.variant[20],
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        background: theme.colors.neutral.variant[10],
      }),
    }),
  sideModal: () =>
    css({
      opacity: 1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 'unset',
      width: SIDE_MODAL_WIDTH,
      height: 'auto',
      transition: styleUtils.generateTransitionStyles(['transform'], `${MODAL_TRANSITION_DURATION_MS}ms`),
      transform: `translateX(${SIDE_MODAL_WIDTH}px)`,
      '&.ReactModal__Overlay--after-open': {
        transform: 'translateX(0px)',
      },
      '&.ReactModal__Overlay--before-close': {
        transform: `translateX(${SIDE_MODAL_WIDTH}px)`,
      },
    }),
  closeModalIcon: () =>
    css({
      position: 'absolute',
      top: 16,
      right: 19,
      cursor: 'pointer',
    }),
} satisfies Styles<ModalStyleStates>;

export const useStyles = withStyles(styles);
