import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { CampaignManagerState } from './CampaignManagerContext.types';

export const initialState: CampaignManagerState = {
  campaigns: {
    data: [],
    fetchNextPage: () => {
      throw Error('not implemented');
    },
    hasNextPage: false,
    isLoading: true,
    count: 0,
    removeCampaignFromOverview: () => {
      throw Error('not implemented');
    },
    markCampaignAsRead: () => {
      throw Error('not implemented');
    },
    isFetchingNextPage: false,
  },
  campaignManager: () => {
    throw Error('not implemented');
  },
  selectedCampaign: undefined,
  isLoadingSelectedCampaign: false,
  filters: {
    status: CampaignOverviewStatus.active,
    teamview: true,
    name: '',

    setStatus: () => {
      throw Error('not implemented');
    },
    setTeamview: () => {
      throw Error('not implemented');
    },
    setName: () => {
      throw Error('not implemented');
    },
  },
  setSelectedCampaignId: () => {
    throw Error('not implemented');
  },
};
