/* eslint-disable react/no-unescaped-entities */

import { Fragment } from 'react';

import { Dictionary } from '../types';

export const dictionary: Dictionary = {
  certification: 'Certification',
  since: 'since',
  notAvailableAnymore: 'No longer available',
  workingHours: 'Working Hours',
  commuteDistance: 'Travel distance',
  relocation: 'Relocation',
  salary: 'Salary',
  preferredJobs: 'Preferred Jobs',
  total: 'Total',
  unableToRefresh: 'Unable to Refresh',
  current: 'Current',
  past: 'Past',
  noDataAvailable: 'No data available',
  cvAvailable: 'CV Available',
  hours: 'Hours',

  login: 'Log In',
  rememberMe: 'Remember Me',
  logout: 'Log Out',
  registerAccount: 'Create an Account',
  newCompany: 'New Company',
  required: 'Required',
  showPassword: 'Show password',
  hidePassword: 'Hide password',
  forgotPassword: 'Forgot Password?',
  forgotYourPassword: 'Forgot your Password?',
  forgotPasswordDescription:
    "A password reset link will be sent to your email to reset your password. If you don't get an email within a few minutes, please re-try.",
  resetPassword: 'Reset Password',
  resetPasswordMailSentDescription: 'A password reset link sent to your e-mail address. Please check your emails.',
  mailSent: 'Mail Sent',
  pleaseEnterYourNewPassword: 'Please Enter Your New Password',
  preferences: 'Preferences',
  email: 'Email',
  emailNotifications: 'Email notifications',
  sendEmail: 'Send email',
  username: 'Username',
  userName: 'User name',
  password: 'Password',
  passwordRepeat: 'Password (Repeat)',
  overview: 'Overview',
  new: 'New',
  viewAll: 'View All',
  statistics: 'Statistics',
  comingSoon: 'Coming Soon',
  administration: 'Administration',
  connections: 'Connections',
  availableCredits: (left, total, isPayPerMatch = false) => (
    <Fragment>
      {isPayPerMatch ? 'Matches' : 'Leads'} <b>{left}</b>/{total} available
    </Fragment>
  ),
  cv: 'CV',
  profile: 'Profile',
  emailAddress: 'Email address',
  emailAddressForTeams: 'Team email address',
  phoneNumber: 'Phone number',
  nickname: 'Nickname',
  companyName: 'Company name',
  companiesWorkedAt: 'Companies worked at',
  companiesWorkedAtPlaceholder: 'e.g. Rabobank',
  companySize: 'Company size',
  companyType: 'Company type',
  selectCountry: 'Select a country',
  selectATS: 'Select your ATS',
  whatATSAreYouUsing: 'What ATS are you using?',
  howDidYouHearAboutUs: 'How did you hear about us?',
  pleaseExplain: 'Please explain...',
  submit: 'Submit',
  save: 'Save',
  next: 'Next',
  previous: 'Previous',
  back: 'Back',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',
  changePassword: 'Change Password',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  repeatNewPassword: 'New Password (Repeat)',
  chooseFile: 'Choose File',
  noFileChosen: 'No File Chosen',
  roles: 'Roles',
  users: 'Users',
  user: 'User',
  ats: 'ATS',

  addedToAts: 'added to ATS',
  jobBoards: 'Job Boards',
  useRolesToGroupPermissions: 'Use Roles to Group Permissions',
  manageUsersAndPermissions: 'Manage Users and Permissions',
  recruitmentAgency: 'Recruitment Agency',
  corporate: 'Corporate',
  freelancer: 'Freelancer',
  selectInHouseRecruiters: 'Select the number of inhouse recruiters',
  cantBeLessThanZeroRecruiters: 'You can’t have less than 0 recruiters',
  thisFieldIsRequired: 'This field is required.',
  validationRequiredEmail: 'Please enter a valid email address.',
  useYourWorkEmailAddress: 'Please use a work e-mail.',
  validationPasswordConfirm: 'Please enter the same value again.',
  editCriteriaForMoreResults: 'Edit criteria for more results',
  newResultsInHours: (hours) => <Fragment>New results in {hours} hours</Fragment>,
  newResultsOnMonday: 'New results on Monday',
  thereAreNewResults: (newResults) => `${newResults} new`,
  untitledSearch: 'Untitled search',
  newSearch: 'New Search',
  searches: 'Searches',
  candidates: 'Candidates',
  teamview: 'Teamview',
  teamView: 'Team view',
  status: 'Status',
  lastAdded: 'Last Added',
  coWorker: 'Co-Worker',
  search: 'Search',
  sortBy: 'Sort by',
  sortedBy: 'Sorted by',
  mySearches: 'My Searches',
  teamSearches: 'Team Searches',
  allSearches: 'All Searches',
  searchOverview: 'Search overview',
  campaignNotificationByEmail: 'Campaign email notifications',
  campaignNotificationDisabled: 'Disabled',
  campaignNotificationOnce: 'Once a day (at 08:00)',
  campaignNotificationTwice: 'Twice a day (at 08:00 and 16:00)',
  campaignNotificationThrice: 'Thrice a day (at 08:00, 12:00 and 16:00)',
  sendToNotificationTitle: 'Where to send the new/updated cadidates e-mail?',
  sendToNotificationPersonal: 'Personal E-mail only',
  sendToNotificationTeam: 'Team E-mail only',
  sendToNotificationBoth: 'Both team and Personal E-mails',
  askBeforeSpendingMonsterInventoryCredit: 'Ask before spending monster inventory credit?',
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // askBeforeSpendingWerkzoekenCredit: 'Ask before spending werkzoeken credit?',
  yesAskEveryTime: 'Yes, ask every time',
  noNeverAsk: 'No, never ask',
  browserExtension: 'Browser extension',
  robinExtension: 'Robin extension',
  adminPanel: 'Admin panel',
  intakePlanned: 'Matched',

  yes: 'Yes',
  no: 'No',
  areYouSure: 'Are You Sure?',
  confirmDeleteCampaign: 'Are you sure you want to delete this campaign?',
  by: 'by',
  you: 'you',
  here: 'here',

  match: 'Match',
  shortlistCandidateToOtherSearch: 'Shortlist to another search',
  shortlistCandidateNameToOtherSearch: (name) => `Shortlist candidate ${name} to other search`,
  shortlistCandidateToOtherSearchDescription:
    'Shortlist candidate for another search & keep in search results for the current search',

  matched: 'Matched',
  hidden: 'Hidden',
  shortlist: 'Shortlist',
  shortlisted: 'Shortlisted',
  unsaved: 'Unsaved',
  saved: 'Saved',
  connectToAts: 'Connect to ats',
  connectToAtsVacancy: 'Connect an ATS vacancy',
  unlocked: 'Unlocked',
  downloadProfiles: 'Download Profiles',
  history: 'History',
  activity: 'Activity',
  profileHistoryPlaceholder:
    'History information appears when you take actions on this candidate such as approving or rejecting.',
  details: 'Details',
  educationLevel: 'Education level',
  employer: 'Employer',
  travelDistance: 'Travel distance',
  availability: 'Availability',
  contactInfo: 'Contactable by',
  Monsterboard: 'Monsterboard',
  Nationalevacaturebank: 'Nationale Vacature Bank',
  Werknl: 'Werk.nl',
  version: 'Version',
  downloadAllApprovedCandidates: 'Download all approved candidates',
  downloadAllAMatched: 'Download all matched',
  maxOf300CandidatesWillBeDownloaded: 'Max of 300 candidates will be downloaded',
  searchForAts: 'Connect an ATS vacancy',

  iAlreadyKnow: 'I Already Know',
  overqualified: 'Overqualified',
  underqualified: 'Underqualified',
  wrongIndustry: 'Wrong Industry',
  wrongEducation: 'Wrong Education',
  wrongExperience: 'Wrong Experience',
  tooFarAway: 'Too Far Away',
  otherReasonNamely: 'Other, namely...',
  other: 'Other',
  hide: 'Hide',
  check: 'Check',

  contact: 'Contact',
  contacted: 'Contacted',
  positiveResponse: 'Positive Response',
  negativeResponse: 'Negative Response',
  noResponse: 'No Response',
  searching: 'Searching',
  sentToAts: 'Sent to ats',
  interviewScheduled: 'Interview scheduled',
  interviewPlaneed: 'Interview planeed',
  interviewCompleted: 'Interview completed',
  interviewCancelled: 'Interview Cancelled',
  leftVoicemail: 'Left voicemail',
  openToWorkNotInterested: 'Open to work, not interested',
  notOpenToWork: 'Not open to work',
  noResponsiveToReachOut: 'Not responsive to reach out',

  experience: 'Experience',
  courses: 'Courses',
  certifications: 'Certifications',
  education: 'Education',
  skill: 'Skill',
  skills: 'Skills',
  language: 'Language',
  languages: 'Languages',
  industry: 'Industry',
  company: 'Company',
  date: 'Date',

  whyRejected: 'Why Rejected?',
  whatIsTheStatus: 'What is the Status?',
  updateStatus: 'Update status',

  explanation: 'Explanation',
  nameSearch: 'Name your search',
  vacancy: 'Vacancy',
  vacancyPlaceholder: 'e.g. https://careers.recruitrobin.com/account-manager',
  jobTitle: 'Job title',
  jobTitles: 'Job titles',
  addSuggestions: (number: number) => `Add ${number} suggestions`,
  jobTitlePlaceholder: 'e.g. Engineer',
  jobTitleWarning: 'Having only one job title will likely generate very few results.',
  workExperience: 'Work experience in years',
  minWorkExperienceInYears: 'Min. Work Experience in Years',
  maxWorkExperienceInYears: 'Max. Work Experience in Years',
  minWorkExperienceInFunction: 'Min. Experience in Function',
  screeningCriteria: 'Screening criteria',
  screeningCriteriaPlaceholder: '',
  country: 'Country',
  postalCode: 'Postal Code',
  rangeInKm: 'Range in KM',
  startSearch: 'Start Search',
  editSearch: 'Edit Search',
  eg: 'E.g.',

  veryWell: 'Very Well!',
  iWillStartLookingForCandidates: (campaignName) => (
    <Fragment>
      I'll start looking for candidates for your job offer <span>{campaignName}</span>
    </Fragment>
  ),
  whatToExpect: 'What to expect',
  whatToExpect1: 'Within 4-24 hours an overview of potential candidates.',
  whatToExpect2: 'I will keep you posted during the coming phases via e-mail.',
  editThisCampaign: 'Edit this Campaign',
  viewCampaigns: 'View Campaigns',
  searchForCampaign: 'Search for Campaign',
  ratioOfApprovedCandidates: (approved: number, total: number) => (
    <Fragment>
      <strong>{approved}</strong> of <strong>{total}</strong> matched
    </Fragment>
  ),
  seeResults: 'See results',
  notAbleDeleteSearchAts: 'You cannot delete a search related to your ATS',

  more: 'More',
  move: 'Move',
  moveTo: 'Move To',

  contactInfoSetting: 'Only show candidates with contact information',
  activeJobSeekersSetting: 'Only show active job seekers',

  //Job Boards
  newJobBoard: 'New Job Board',
  editJobBoard: 'Edit Job Board',
  invalidCredentials: 'Invalid Credentials',
  jobBoardCredentialVerificationTitle: 'Verifying your credentials.',
  jobBoardCredentialVerificationDescription:
    'Please wait a moment, live verification of credentials can take up to a minute.',
  jobBoardInvalidCredentials:
    'Sorry, we could not verify your credentials, check your username and password and try again.',
  jobBoardDuplicatedCredentials: 'Your credentials are already saved.',
  jobBoardConnectionActive: 'Job board connection is active.',
  jobBoardConnectionInActive: 'Job board connection is not active.',
  confirmDeleteJobBoard: 'Are you sure you want to delete this Job Board?',
  confirmDisableJobBoard: 'Are you want to disable this job board for all users?',
  jobBoardExplanation: 'Choose the job board you would like to connect with\n',
  jobBoardUsernameExplanation: 'Enter the username you use to login onto the job board.',
  jobBoardPasswordExplanation: 'Enter the password you use to login onto the job board.',
  jobBoardSecret: 'Security Question',
  jobBoardSecretExplanation: 'Enter the secret question answer you use to login onto the job board.',
  licenseActive: 'Your credential is active.',
  licenseInActive: 'Your credential is not active.',
  license: 'License',
  addLicense: 'Add License',

  //ATS
  addNew: 'Add New',
  atsVacancy: 'ATS Vacancy',
  viewATSConnections: 'View ATS Connections',
  unableToConnectToATS: 'Unable to connect to your ATS. Please check your credentials or try again later.',
  myConnections: 'My Connections',
  teamConnections: 'Team Connections',
  usersWithoutConnections: 'Users Without Connections',
  atsKey: 'ATS Key',
  atsUrl: 'ATS URL',
  atsName: 'ATS Name',
  atsCompleteName: 'Applicant tracking system',
  clientSecret: 'Client Secret',
  requestATSConnection: 'This ATS is not yet registered in our sytem. Request a connection, so we can add the ATS.',
  atsRequestedMessage: (
    <Fragment>
      The connection is requested. We are on it! In the meanwhile you can continue with creating searches and judging
      candidates.
      <br />
      <br />
      Whenever we know if it's possible to connect with the ATS you requested, we will contact you.
    </Fragment>
  ),
  checkOverview: 'Check Overview',
  confirmDeleteATS: "Deleting this connection means your candidates won't be pushed to your ATS anymore.",
  confirmSwitchATS: (ats) => `You are already connected to ${ats}. Are you sure you would like to switch?`,
  atsExplanation: 'Choose the ATS you would like to connect with',
  atsUsernameExplanation: 'Enter the username you use to login to the ATS',
  atsPasswordExplanation: 'Enter the password you use to login to the ATS',
  atsKeyExplanation: 'Enter the ATS Key you find in your ATS’ settings',
  atsSecret: 'Client secret',
  atsSecretExplanation: 'Enter the client secret you fin',

  campaignCompletedTitle: 'Doing Well!',
  campaignCompletedMessage: (
    <Fragment>
      You've reviewed all available candidates.
      <br />
      <br />
      Search again or edit your current search so we can look for more relevant candidates.
    </Fragment>
  ),
  campaignGroup1CompletedMessage: (
    <Fragment>
      You've reviewed all candidates. I have 25 more candidates that fit most, but not all of your search criteria.
      These candidates could be relevant for you. Would you like to review them?
      <br />
      <br />
      Alternatively, you can edit your current search so we can start searching for more candidates.
    </Fragment>
  ),
  searchingMoreProfiles: 'Searching More Profiles.',
  searchAgain: 'Search Again',
  reviewAlternativeCandidates: 'Review Alternative Candidates',

  mustBeMoreThanMin: 'Must be more than min',
  mustBeLessThanMax: 'Must be less than max',

  // common
  any: 'Any',
  of: 'of',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  activate: 'Activate',
  deactivate: 'Deactivate',
  close: 'Close',
  remove: 'Remove',
  exclude: 'Exclude',
  mustHave: 'Must Have',
  saving: 'Saving...',
  done: 'Done!',
  loading: 'Loading',
  thisCouldTakeAFewMinutes: 'This could take a few minutes.',
  changesSaved: 'Changes saved',
  invalidUsername: 'Invalid Username',
  invalidPassword: 'Invalid Password',
  clickHere: 'Click here',
  selectAll: 'Select All',
  clearAll: 'Clear All',
  scrollTo: 'Scroll To',
  otherwiseNamely: 'Otherwise, namely ...',
  somethingWentWrong: 'Something went wrong',
  pleaseTryAgainOrContactUs: 'Please try again. If the problem persists contact us.',
  notFound: 'Not Found',
  loggingYouInAutomatically: 'Logging You in Automatically',
  couldNotLogYouInAutomatically: 'Could not log you in Automatically',
  attemptNofX: (current: number, max: number) => `Attempt ${current} of ${max}.`,
  minimumCharacterValue: (value) => `A minimum of ${value} characters is required`,
  maximumCharacterValue: (value) => `The maximum number of characters is ${value}`,
  companyNameCannotContainSpecialCharacters: 'Your company name cannot have spaces or special characters.',
  selectAnOption: 'Please select an option',
  and: 'and',
  or: 'or',
  expandLess: 'Expand less',
  expandMore: 'Expand more',
  downloadProfile: 'Download profile',
  tutorial: 'Tutorial',
  emailSuccessfullySent: 'Your email was successfully sent!',
  emailFailedSend: 'Your email failed to send!',
  linkedinSuccessfullySent: 'Your LinkedIn request was successfully sent!',
  linkedinFailedSend: 'Your LinkedIn request failed to send!',
  linkedinInMailSuccessfullySent: 'Your LinkedIn InMail was successfully sent!',
  liknedinInMailFailedSend: 'Your Liknedin Inmail failed to sent!',
  indeedSuccessfullySent: 'Your Indeed message was successfully sent!',
  indeedFailedSend: 'Your Indeed message failed to sent!',

  //Languages
  english: 'English',
  dutch: 'Dutch',

  //Users
  actions: 'Actions',
  exportToExcel: 'Export to Excel',
  createNewUser: 'Create new user',
  manageUsers: 'Manage users',
  userUnlock: 'Unlock',
  userActivationSettings: 'Activation settings',
  isActive: 'Is active',
  lastLoggedIn: 'Last login time',
  userSince: 'User since',
  emailConfirmed: 'Email confirmed',

  userDeactivateConfirmation: 'Are you sure you want to deactivate this user?',
  userActivateConfirmation: 'Are you sure you want to activate this user?',
  userWasUnlockedSuccessfully: 'User unlocked successfully',
  userWasDeletedSuccessfully: 'User deleted successfully',
  userCreatedSuccessfully: 'User created successfully',
  userProfileUpdatedSuccessfully: 'User updated successfully',
  userWasActivatedSucessfully: 'User activated successfully',

  editUser: 'Edit user',
  invalidEmailAddress: 'Please enter a valid Email.',
  invalidPhoneNumber: 'Please enter a phone number.',
  atLeast6Characters: 'Required at least 6 characters.',

  userPasswordSettings: 'Password settings',
  setNewPassword: 'Set password.',
  setRandomPasswordForUser: 'Set random password.',
  shouldChangePasswordOnNextLogin: 'Should change password on next login.',
  sendActivationEmail: 'Send activation email.',
  setUserIsActive: 'Active',
  setLockOut: 'Lockout enabled',
  remindPassword: 'If you can not remember your password,',
  incorrectPassword: 'Incorrect Password',
  passwordsDoNotMatch: "Passwords don't match",

  //While the user is logged out
  emailActivation: 'Email Activation',
  emailActivationDescription:
    "A link will be sent to your email to activate your email address. If you don't get an email within a few minutes, please re-try.",
  emailActivationSentDescription: 'An e-mail activation link sent to your e-mail address. Please check your emails.',

  invalidResetLink: 'Invalid Reset Link',
  invalidResetLinkDescription: 'The reset link you used is not valid',
  passwordResetComplete: 'Password Reset Complete',
  goToLogin: 'Go to Log In Page',
  goToLoginDescription: 'You may now log in with your new password',

  companyNameIsTaken: 'This company name is already taken. Please try it again with a different company name.',

  //Realtime Search filters
  activeFilters: (num) => `${num} active filter${num === 1 ? '' : 's'}`,
  filters: 'Filters',
  totalExperience: 'Total experience',
  location: 'Location',
  locations: 'Locations',
  locationsPlaceholder: 'e.g. Utrecht',
  skillsKeywords: 'Skills / Keywords',
  skillsKeywordsPlaceholder: 'e.g. Excel',
  mustHaveEmail: 'Must Have Email',
  mustHavePhone: 'Must Have Phone',
  sources: 'Source',
  schoolName: 'Schools attended',
  schoolNamePlaceholder: 'e.g. Utrecht University',
  educationDegree: 'Education degrees',
  educationDegreePlaceholder: 'e.g. Bachelors',
  educationFields: 'Education fields',
  educationFieldsPlaceholder: 'e.g. Human Resource Management',
  sizeOfBatches: 'Size of batches',
  locationDistanceInKm: 'Location distance (km)',
  industries: 'Industries',
  aboutMe: 'About Me',
  licenses: 'Licenses',
  searchResults: 'Search results',
  updatedAt: 'Updated At',
  graduationYear: 'Latest education',
  employmentType: 'Employment type',

  //Updated At Filter
  anyTime: 'Any time',
  last24Hours: 'Last 24 Hours',
  last3Days: 'Last 3 Days',
  lastWeek: 'Last Week',
  lastMonth: 'Last Month',
  last3Months: 'Last 3 Months',
  last6Months: 'Last 6 Months',
  lastYear: 'Last Year',

  //Employment Type Filter
  employmentTypeFullTime: 'Full time',
  employmentTypePartTime: 'Part time',
  employmentTypeInternship: 'Internship',
  employmentTypeFreelance: 'Freelance',
  employmentTypeShortTerm: 'Short term',
  employmentTypeSeasonal: 'Seasonal',

  //Job Title Type Filter
  jobTitleType: 'When experience happened',
  jobTitleTypeAll: 'Any time',
  jobTitleTypeCurrent: 'Latest role',
  jobTitleTypeRecent: 'Latest two roles',
  jobTitleTypePast: 'Past roles',

  //Realtime Search Results
  candidatePreferences: 'Candidate Preferences',
  interests: 'Interests',
  source: 'Source',
  lastUpdated: 'Last updated',
  lastCrawled: 'Last refreshed',
  lastMatched: 'Last matched',
  lastContacted: 'Last contacted',
  lastHidden: 'Last hidden',
  lastShortlisted: 'Last shortlisted',
  couldNotRefreshProfile:
    'We cannot refresh this profile at this time. The profile may have been deleted at the source. Please try again later.',
  profileNotAvailable:
    'We cannot refresh this profile at this time because the profile has been deleted at the source.',
  phone: 'Phone',
  academicExperience: 'Academic Experience',
  addSomeFiltersMessage: 'Add some filters to start your search!',
  noResultsMessage:
    'We found no candidates for the filters you requested. Try changing the filters or use the chat if you need help!',
  openToWork: 'Open to Work',
  credentialsDisabledCallToAction: (platform: string) =>
    `Your credentials for ${platform} are not working. Please update them, so you don't miss out on candidates from ${platform}.`,
  autoSavedFilters: 'Auto-saved Filters',

  helpCenter: 'Help center',
  help: 'Help',

  notifications: 'Notifications',
  markNotificationAsRead: 'Mark notification as read',
  markAllAsRead: 'Mark all as read',
  seeSearchInList: 'See search in list',
  year: 'year',
  years: 'years',
  month: 'month',
  months: 'months',
  inTotal: 'in total',
  inFunction: 'in function',
  viewFullProfile: 'View Full Profile',
  pleaseWait: 'Please Wait',
  downloadCV: 'Download CV',
  downloadCVs: 'Download CVs',
  pageNumOfNum: (current: number | string, total: number | string) => `Page ${current} of ${total}`,
  issued: 'Issued',
  resume: 'About',
  licensesAndCertifications: 'Licenses & Certifications',
  jobs: 'Jobs',
  minWorkHours: 'Min. work hours',
  maxWorkHours: 'Max. work hours',
  minSalary: 'Min. salary',
  maxSalary: 'Max. salary',
  currency: 'Currency',
  unlockContactInformation: (price: number) => `Unlock contact information (${price} credit${price >= 2 ? 's' : ''})`,
  saveAndUnlockContactInformation: (price: number) =>
    `Save and unlock contact information (${price} credit${price >= 2 ? 's' : ''})`,
  unlockCV: (price: number) => `Unlock CV (${price} credit${price >= 2 ? 's' : ''})`,
  currentOfTotal: (current: number | string, total: number | string) => `${current} of ${total}`,
  showMore: 'show more',
  showLess: 'show less',
  showXMore: (quantity) => `Show ${quantity} more`,
  showXLess: (quantity) => `Show ${quantity} less`,
  showAll: (quantity) => `Show all (${quantity})`,
  requestContactInformation: 'Request contact information',
  youCanRequestContactInformation:
    'You can request contact information. We will charge one credit if we succeed to find one.',
  thisProfileWasUpdatedTimeAgo: (timeAgo) => `This profile was updated ${timeAgo}`,
  thisProfileWasRefreshedTimeAgo: (timeAgo) => `This profile was refreshed ${timeAgo}`,
  thisProfileWasApprovedTimeAgo: (timeAgo) => `This profile was approved ${timeAgo}`,
  loading_ats_synchronization_label: 'ATS Synchronization',
  loading_contact_info_label: 'Contact Info',
  reTriggerATSCandidateSync: 'Retry send candidate to ATS',
  errorSectionTitle: 'Error',
  errorSectionMissingSkillOrFunction: 'You should specify a skill or function title or location',
  hideFromApproved: 'Hide from approved',
  userFormGenericError: 'Something went wrong while saving the user',
  userFormEmailAlreadyTakenError: 'The e-mail @email is already taken',
  duplicate: 'Duplicate',
  copyOf: 'Copy of',
  clickHereToUpdateSearch: 'Click here to update the search results with your new filters',
  orSimplyPress: ({ isMacOs = false }) => (
    <Fragment>
      Or simply press&nbsp;<code>{isMacOs ? 'Cmd' : 'Ctrl'} + Enter</code>
    </Fragment>
  ),

  // Teams
  team: 'Team',
  teams: 'Teams',
  teamName: 'Team name',
  teamUsers: 'Users in team',
  manageTeams: 'Manage teams',
  createNewTeam: 'Create new team',
  createdBy: 'Created by',
  createdAt: 'Created at',
  editTeam: 'Edit team',
  teamDeletionConfirmation: 'Are you sure you want to delete this team? This action cannot be undone.',
  teamWasDeletedSuccessfully: 'Team deleted successfully',
  teamCreatedSuccessfully: 'Team created successfully',
  teamUpdatedSuccessfully: 'Team updated successfully',
  editTenantEmailSettingsExplanation:
    "This configuration only works when you don't have a team. You can set a notification configuration for a team individually. Anything set within a specific team will override the settings defined here.",
  editTenantEmailSettingsBtn: 'Company notification email',
  editTenantEmailSettings: 'Edit company notification email settings',
  tenantEmailSettingsCreatedSuccessfully: 'Company notification email settings successfully created',
  tenantEmailSettingsUpdatedSuccessfully: 'Company notification email settings successfully updated',
  joinTeamSuccessfully: 'You joined the team',
  exitTeamSuccessfully: 'You left the team',
  join: 'Join',
  leave: 'Leave',
  detailsTeam: 'Team details',

  updateContactInformation: 'Update contact information',
  contactInfoAvailableFrom: (platform: string) => `Contact info available from ${platform}`,
  requestHere: 'Request here',
  refreshHappensAutomatically: 'Refreshing now happens automatically',
  notificationJobbboardNewVersion: (platform: string) =>
    `A brand new ${platform} integration has been released, please contact us about getting connected to continue seeing the latest ${platform} candidates.`,

  requestJobboardInformationConfirmationTitle: (platform: string) =>
    `Request candidate contact information from ${platform}`,
  requestJobboardInformationConfirmationDescription: (platform: string) =>
    `This will use a ${platform} Credit, are you sure you want to request this information?`,
  requestJobboardInformationConfirmationCancelText: 'Cancel info request',
  requestJobboardInformationConfirmationConfirmText: 'Request contact info',

  dontPerformThisCheckNextTime: "Don't perform this check next time",

  candidateNotFoundTitle: 'Unfortunately the candidate you are looking for is no longer available',
  candidateNotFoundSubtitle: 'They have removed their profile at the source.',

  /// Platforms
  indeed: 'Indeed',
  linkedIn: 'LinkedIn',
  linkedInInMail: 'LinkedIn InMail',
  whatsApp: 'WhatsApp',

  /// Contact Modal
  chooseContactTitleModal: 'Choose a contact method',
  chooseContactDescriptionModal: (name) => `Select how you want to contact ${name}`,
  writeEmailTo: (name) => `Write your email to ${name}`,
  writeMessageTo: (name) => `Write your message to ${name}`,
  sendWithWhatsAppWeb: 'Send with WhatsApp Web',
  sendWithIndeed: 'Send with Indeed',
  sendLinkedInRequest: 'Send LinkedIn connection request',
  sendInMail: 'Send InMail',
  sentWhatsAppTo: (name: string) => `Whatsapp message sent to ${name}`,
  emailSent: 'Email sent',
  emailReceived: 'Email received',
  emailReceivedFrom: (name: string) => `Email received from ${name}`,
  reply: 'Reply',
  indeedSent: 'Indeed sent',
  whatsappSent: 'Whatsapp sent',
  linkedinConnectionSent: 'LinkedIn connection sent',
  linkedinInmailSent: 'LinkedIn Inmail sent',
  sentEmailTo: (name: string) => `Email sent to ${name}`,
  sentOutreachMessageTo: (channel: string, candidateName: string) => `${channel} sent to ${candidateName}`,
  sentIndeedTo: (name: string) => `Indeed message sent to ${name}`,
  sentLinkedInTo: (name: string) => `Linkedin connection sent to ${name}`,
  sentLinkedInMailTo: (name: string) => `Linkedin InMail sent to ${name}`,
  sentWerknlMailTo: (name: string) => `Werknl message sent to ${name}`,
  sentBy: 'Sent by:',
  sendDisabledIndeedTip: 'You must connect your Indeed account to send this message',
  sendDisabledLinkedinTip: 'You must connect your LinkedIn account to send this message',

  /// Connect Phone Modal
  connectYourPhoneTitleModal: 'Connect your phone to WhatsApp',
  connectYourPhoneDescriptionModal:
    'To send messages via WhatsApp, please use the desktop app. Download and install the app on your computer and connect your mobile phone. After this you’re ready to go!',
  showMeHow: 'Show me how',
  dontShowThisAgain: 'Don’t show this again',
  imConnected: 'I’m connected',
  downloadTheApp: 'Download the app',

  /// Browser Extension Modal
  browserExtensionRequired: 'Browser extension required',
  browserExtensionRequiredDescriptionModal:
    'To send Indeed or LinkedIn messages you must have our browser extension installed so we can communicate with those platforms. We’ve noticed you don’t have the extension, so please install this first.',
  installTheExtension: 'Install the extension',

  /// Confirm Message Modal
  confirmMessageTitleModal: 'Did you successfully send your message?',
  confirmMessageDescriptionModal:
    'Let us know if you successfully sent the message or not so we can organise where to put the candidate for you!',
  messageNotSent: 'Message not sent',
  messageSent: 'Message sent',

  /// Confirm Call Modal
  confirmCallTitleModal: (name) => `Did ${name} pick up the phone?`,
  confirmCallDescriptionModal:
    'Let us know if your call was successful or not so we can organise where to put the candidate for you!',
  couldntConnect: 'Not Contacted',
  connected: 'Contacted',
  callSaved: 'Call Saved',

  /// Fields tips
  campaignNotificationByEmailTip: 'Enable receiving notifications of new candidates found for your campaigns',
  nameSearchTip: 'Provide a clear name that you can easily recognise, your team will also use this!',
  jobTitleTip: 'Add a relevant job title to search for, we then suggest 10 more relevant titles',
  jobTitleTypeTip: 'When the above positions were worked by the candidate',
  locationDistanceInKmTip: 'Search radius from selected locations (not travel distance)',
  contactInfoTip: 'When both options are checked, only candidates that have both on their profile will be shown',
  totalExperienceTip: 'How many years the candidate has been working in total',
  languagesTip: 'What languages the candidate needs to know for the role',
  lastUpdatedTip: 'When the candidates profile was last updated on the job boards',
  graduationYearTip: 'When the candidate had last received education of any form',
  schoolNameTip: 'What educational institutes the candidate should have attended',
  educationDegreeTip:
    'This is a degree the candidate needs to have but it is not necessarily the highest or only degree they have',

  /// Admin
  backToSearchCampaigns: 'Back to search campaigns',
  createNewCompany: 'Create a new company',
  editCompany: 'Edit company',
  editUsers: 'Edit users',
  impersonateUser: 'Impersonate user',
  creditLimit: 'Credit limit',
  updateUsersSuccessfully: 'Users has been updated successfully',
  id: 'Id',
  companyCode: 'Company Code',
  edition: 'Edition',
  active: 'Active',
  inactive: 'Inactive',
  credits: 'Credits',
  created: 'Created',
  updated: 'Updated',
  demo: 'Demo',
  standard: 'Standard',
  tenantHasBeenUpdatedSuccessfully: 'Tenant has been updated successfully',
  tenantHasBeenCreatedSuccessfully: 'Tenant has been created successfully',
  tenantName: 'Tenant name',
  removeSpaces: 'Remove spaces',
  subscriptionEdition: 'Subscription edition',
  editTenantUsers: 'Edit tenant users',
  editTenantUsersSubtitle: 'The first user in this list is the admin user and is required.',
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  fullName: 'Full name',
  lastPosition: 'Last position',
  lastCompany: 'Last company',
  recruiterFullName: 'Recruiter full name',
  NameJobboard: 'Name Jobboard',
  NameVacancy: 'Name Vacancy',
  Location: 'Location',
  addAnotherUser: 'Add another user',
  impersonate: 'Impersonate',
  searchCompany: 'Search company',
  entriesPerPage: 'Entries per page',

  matchPhase: 'Match phase',
  none: 'None',

  website: 'Website',

  typeYourMessage: 'Type your message...',
  typeYourNote: 'Type your note...',
  subject: 'Subject',
  signOff: 'Sign off,',
  featureToggles: 'Feature toggles',
  addFeatureToggle: 'Add feature toggle',
  title: 'Title',
  value: 'Value',
  enabled: 'Enabled',
  emptySubject: 'You have not entered a subject',
  emptyMessage: 'You have not entered a message',
  emptySignOff: 'You have not entered a greeting',
  yearAbbr: 'yr',
  yearsAbbr: 'yrs',
  simplified: 'Simplified',
  addLabels: 'Add labels',
  labelAdded: 'Label added',
  noteAdded: 'Note added',
  fresh: 'Fresh',
  candidateHasXNotes: (labelsCount) => `Candidate has ${labelsCount} notes`,

  // Candidate Insights
  newCandidate: 'New candidate',
  updatedInformation: 'Updated information',
  educationAddedRecently: 'Education item added',
  educationDatesChanged: 'An education date was changed',
  educationEditedRecently: 'An education was changed',
  experienceAddedRecently: 'Experience added recently',
  jobRoleChanged: 'A job role was changed',
  jobDescriptionChanged: 'A job description was changed',
  jobDatesChanged: 'A job date was changed',
  skillAddedRecently: 'Skill added recently',
  preferredEmploymentTypeChanged: 'Preferred employment type changed',
  preferredWorkHoursChanged: 'Preferred work hours changed',
  workAvailabilityChanged: 'Work availability changed',
  locationChangedRecently: 'Location changed recently',
  phoneAddedRecently: 'Phone added recently',
  phoneNumberChanged: 'Phone number changed',
  emailAddedRecently: 'Email added recently',
  emailChanged: 'Email changed',

  latestActions: 'Latest actions:',
  titlePlaceholder: 'Name your search',
  refineTheSearchBelow: 'Refine the search below',

  indeedNoCredits: 'Your indeed account does not have credits to send an Indeed email',

  createNewTemplate: 'Create new template',
  nameYourTemplate: 'Name your template',
  blankTemplate: 'Blank template',

  saveTemplate: 'Save template',
  selectTemplateToUse: (type) => `Select a ${type} template to use`,
  templateSavedSuccessfully: 'Template saved successfully!',
  templateTokens: 'Personalization tokens',
  lastActioned: 'Last actioned',
  oldestActioned: 'Oldest actioned',
  haveSeenItAll: 'Yay! You have seen it all!',
  sharedByFromSearch: ({ userName, searchName }) => (
    <>
      Shared by {userName} from {searchName}
    </>
  ),
  copyLinkCandidateProfile: 'Copy a link to the candidate profile',
  linkCopySuccess: 'Link copied!',
  linkCopyFail: 'Link copy failed',
  youNotHaveAccessProfile: 'You do not have access to this candidate profile',
  tryResendAts: 'Try to resend candidate to ATS',

  emailAndPhoneAvailableUponApproval: (email, phone) => {
    if (email && phone) {
      return `${dictionary.email} and ${dictionary.phone} available upon approval`;
    }

    if (phone) {
      return `${dictionary.phone} available upon approval`;
    }

    return `${dictionary.email} available upon approval`;
  },

  cvAvailableUponApproval: 'CV available upon approval',

  changeOwner: 'Change owner',
  currentOwner: 'Current owner',
  makeUserSearchOwner: (userName) => `Make ${userName} the search owner?`,
  makeUserSearchOwnerDescription: (
    userName,
  ) => `You will no longer be the owner of the search as ${userName} will replace you.
  `,
  goAhead: 'Go ahead!',
  currentSearch: 'Current search',
  labelsChanged: 'Labels changed',
  notes: 'Notes',
  startTypingYourNotesHere: 'Start typing your notes here...',
  moreInfoSent: 'More info sent',
  notResponding: 'Not responding',
  notInterested: 'Not interested',
  positiveOutcomes: 'Positive outcomes',
  neutralOutcomes: 'Neutral outcomes',
  negativeOutcomes: 'Negative outcomes',
  customLabels: 'Custom labels',
  createCustomLabel: 'Create custom label',
  removed: 'Removed',
  paymentMethod: 'Payment method',
  triggerToSendCandidateToAts: 'Trigger to send candidate to ATS',
  chooseAnOption: 'Choose an option',
  selectATemplateToUse: (type) => {
    if (type === 'email') return 'Select an email template to use';
    if (type === 'indeed') return 'Select an Indeed template to use';
    if (type === 'linkedin') return 'Select a LinkedIn template to use';
    if (type === 'whatsapp') return 'Select a WhatsApp template to use';
    if (type === 'werknl') return 'Select a Werknl template to use';
    return 'Select a phone template to use';
  },
  templates: 'Templates',
  templateName: 'Template name',
  nameTheTemplate: 'Name the template',
  newOutreach: 'New outreach',
  content: 'Content',
  signature: 'Signature',
  signatureSave: 'Save the signature',
  signaturePlaceholder: 'Enter your signature here...',
  signatureDescription: 'Customize your email signature for outgoing messages.',
  signatureImageHelper: 'We only support jpeg and png',
  calling: 'Calling',
  candidateNotes: 'Candidate notes',
  successfull: 'Successfull',
  unsuccessfull: 'Unsuccessfull',
  findMoreGreatCandidates: 'Find more great candidates',
  tryAnotherOutreachMethod: 'Try another outreach method',
  outreachPhoneSuccessfullHeaderText: 'Great work, what was the outcome of your conversation?',
  outreachPhoneUnsuccessfullHeaderText: 'Sorry to hear your call was unsuccessful. What was the problem?',
  filterByLabel: 'Filter by label',
  outreachResponseNoticeEmail: (
    <span>
      Sent emails do not appear in your outbox because we send emails on your behalf.
      <br />
      Replies are sent to your email inbox
    </span>
  ),
  outreachResponseNoticeWhatsApp: 'WhatsApp replies will appear directly in your personal WhatsApp app',
  outreachResponseNoticeInMail: 'InMail replies will appear directly in your LinkedIn account',
  outreachResponseNoticeLinkedIn: 'LinkedIn connection replies will appear directly in your LinkedIn account',
  outreachResponseNoticeIndeed: 'Indeed replies will appear directly in your Indeed account',
  outreachResponseNoticeWerknl: (
    <span>
      Sent werk.nl message do not appear in your outbox because we send messages on your behalf.
      <br />
      Replies are sent to your email inbox.
    </span>
  ),
  jobboardRestrictionMessage: (days, platform) =>
    `Due to restrictions of ${platform} it is only possible to send a new message to this candidate in ${days} ${
      days === 1 ? 'day' : 'days'
    }.`,
  unavailableOnThisContactMethod: 'Unavailable',
  browserExtensionNotInstalled: 'Install our browser extension',
  needsLoginOnJobboard: 'Login on job platform',
  needsConnectMobileApp: 'Download and do login on Robin mobile app',
  notesModificationHistory: 'Notes modification history',
  lastEditedBy: 'Last edited by',
  seeFullHistory: 'See full history',
  emailCandidate: 'Email candidate',
  callCandidate: 'Call candidate',
  workingOnIt: 'Working on it...',
  call: 'Call',
  callMobileByPushNotification: 'Call via mobile app',
  searchNameOrOwnerOfTheSearch: 'Search name or owner (first name, last name, or email address)',
  candidateNoLongerAvailable: 'This candidate is no longer available',
  canceledByUser: 'Canceled by user',
  callingIsAutomaticDescription:
    'Calling is automatic when you have a VoIP system or your phone is connected to your computer',
  makeActive: 'Make active',
  makeInactive: 'Make inactive',
  filterBy: 'Filter by',
  lastContactedBy: ({ when }) => `This candidate was last contacted ${when} `,
  noTemplateAvailable: 'No template available',
  createNewSearch: 'Create new search',
  creatingNewSearch: 'Creating new search...',

  consumerKey: 'Consumer key',
  consumerSecret: 'Consumer secret',
  atsConnectedSuccessfully: (ats) => `${ats} connected successfully`,
  weAreConnectingToYourATS: 'We are connecting to your ATS...',
  noCreditsAvailable: 'No credits available',
  selectAProject: 'Select a project',
  emailOrPhone: 'Email or phone',
  candidateSuccessfullyShortlistedToAnotherSearch: 'Candidate successfully shortlisted to another search',

  noCreditsAvailableForMonsterboardTitle: "You've run out of Monsterboard credits.",
  noCreditsAvailableForMonsterboardDescription: () => (
    <>
      Therefore, you no longer have access to Monsterboard candidate contact information. You can request new credits at{' '}
      <strong>support@recruitrobin.com</strong>. You will then receive a proposal from us. The proposal is free of
      charge and will be sent to the 'admin' of your Robin account.
    </>
  ),
  errorRetrievingDepartments: 'Error retrieving departments',
  noDepartmentsFound: 'No departments found, please check your RecruitNow dashboard',
  activateBeforeSearchDepartments: 'You must active this user before select a department',
  candidateAlreadyInteracted: 'This candidate was previously actioned upon',
  newVersionAvailable: 'New version available',
  newVersionAvailableDescription: () => (
    <>
      <u>Click here</u> to reload the page with the new version
    </>
  ),
  continue: 'Continue',
  languageSelection: 'Language Selection',
  googleTranslateMessage: () => (
    <span>
      Using Google Chrome's automatic translation will render our program unusable. To ensure a working app, you will
      not be able to use our program until you disable it.{' '}
      <a href="https://help.recruitrobin.com/nl/articles/8286616-gekke-vertalingen" target="_blank" rel="noreferrer">
        Read how to do that here!
      </a>
    </span>
  ),
  maxAsteriskReached: "Sorry, you've exceeded the limit of 5 titles, including (*)",
  maxAsteriskReachedModalDescription:
    'In the field job titles or skills/keywords, a maximum of 5 asterisks (*) are allowed. Please edit your search query to proceed.',
  maxAsteriskReachedModalClickHere: ({ href }) => (
    <span>
      <a href={href} target="_blank" rel="noreferrer">
        Click here
      </a>{' '}
      for more information.
    </span>
  ),
  hideCandidatesThat: 'Hide candidates with status',
  forPeriodOf: 'For period of',
  aiSuggestions: 'AI suggestions',
  searchInputPlaceholder: 'e.g. Operator in Utrecht with MBO, Jan Bakker, 0612345678',
  findCandidates: 'Find candidates',
  lastSearches: 'Last searches',
  noSearchesFound: 'No searches found',
  didntFindJobTitleLocation: "Didn't find any job title or location. Please retry",
  robinYearWrapped: ({ year }) => `Robin ${year} wrapped`,
  robinWrapped: 'Robin wrapped',
  downloadYearWrapped: ({ year }) => `Download the ${year} wrapped`,
  wrappedYear: ({ year }) => `${year} wrapped`,
  wrappedSummary: ({ tenantName }) => `A comprehensive summary of "${tenantName}" activity`,
  matchedInYear: ({ year }) => `Matched in ${year}`,
  shortlistedInYear: ({ year }) => `Shortlisted in ${year}`,
  contactedInYear: ({ year }) => `Contacted in ${year}`,
  topJobboard: 'Top jobboard',
  mostActiveUser: 'Most active users',
  totalAction: 'Total actions',
  lengthLimitReached: 'Length limit reached',
  getConnectedInstantly: 'Get Connected Instantly!',
  downloadOurMobileApp: 'Download our mobile app',
  downloadOurMobileAppDescription: ' and enable notifications. This quick setup connects you directly with candidates',
  yourCallIsJustATapAway: 'Your call is just a tap away!',
  checkYourPhoneForADirectCall:
    "Check your phone for a 'Direct Call' notification and tap to contact with candidate instantly!",
  downloadRobinMobileApp: 'Download Robin app!',
  selectYourDevicePlatform: "Select your device's platform to download our app.",
  tapToCallCandidate: (candidateName) => `Tap to call “${candidateName}” now!`,
  youCanCopyTheLinkPressingCtrlC: 'You can copy the link by pressing Ctrl (Command) + C',
  info: 'Info',
  success: 'Success',
  error: 'Error',
  warning: 'Warning',
  welcomeBack: 'Welcome back!',

  dashboard: 'Dashboard',
  summary: 'Summary',
  summaryOfActions: 'Summary of actions',
  addSummary: 'Add summary',
  removeSummary: 'Remove summary',
  allCampaigns: 'All campaigns',
  allUsers: 'All users',

  overviewOfActions: 'Overview of actions',
  onlyLastThreeMonths: 'only last 3 months',

  mostUsedJobboardsForMatched: 'Matches per source',
  leaderboard: 'Leaderboard',
  summaryOfActivityForUsers: 'Summary of activity for users / teams',
  allActions: 'All actions',
  campaignCreated: 'Campaign created',
  campaignListEmptyStateMessage:
    'Use the search bar above to start a new search and find the perfect candidates across multiple job boards',

  exportInPDF: 'Export in PDF',
  noMoreResults: 'No more results',
  usersSelected: 'users selected',
  campaignsSelected: 'campaigns selected',
  clickHereToLoginBackIn: 'To connect, click the button, and then login to your',
  browserExtensionIsDisabledOrNotInstalled:
    'The browser extension is disabled or not installed. Check the status or reinstall via the button!',
  clickHereToRefreshThisPage: 'Click here to refresh this page',
  toDisconnectClickTheButton: 'To disconnect, click the button, and then log out from your',
  shouldInstallBrowserExtensionTitle: 'Robin extension',
  shouldInstallBrowserExtensionModal: (
    <span>
      You do not have our browser extension installed. To take advantage of hourly new candidates notifications and the
      ability to contact LinkedIn and Indeed candidates through Robin please install the extension.
      <br />
      You can install the extension by visiting{' '}
      <a
        href="https://chromewebstore.google.com/detail/recruit-robin/edfebfbdfncaeokbcjehncoelddchlhi?hl=nl"
        target="_blank"
        rel="noreferrer">
        this link
      </a>
    </span>
  ),
  shouldCallWerknlOutreachTitle: 'eHerkenning is not connected',
  shouldCallWerknlOutreachModal: (
    <span>
      Sending messages via werk.nl is not yet set up for your organisation. We need an authorization from your
      organisation to activate this feature. Ask your manager, contact us via{' '}
      <a href="mailto:support@recruitrobin.com">support@recruitrobin.com</a> or follow{' '}
      <a
        href="https://help.recruitrobin.com/nl/articles/9320876-robin-machtigen-voor-werk-nl-eherkenning"
        target="_blank"
        rel="noreferrer">
        these steps
      </a>{' '}
      to set up the authorization.
    </span>
  ),
  linkedinRunOutCreditsMessage:
    "You've used all 5 of your free personalized invitations for this month. LinkedIn will reset next month. You can still send connection requests.",
  linkedinRunOutCreditsSendMessage: 'Send linkedIn connection request',
  linkedinRunOutCreditsSendingMessage: ({ onClick }) => {
    return (
      <span>
        Sorry, your message wasn't send
        <br />
        You where out of credits to send
        <br />
        messages on LinkedIn. You can still send
        <br />
        connection invites.{' '}
        <b onClick={onClick}>
          <u>Click here</u>
        </b>{' '}
        to send a<br />
        connection invite.
      </span>
    );
  },
  werknlMessageTo: (name) => `Werk.nl message to ${name}`,
  werknlInputEmail: 'Email (required if phone not provided)',
  werknlInputPhone: 'Phone (required if email not provided)',

  emailSettings: 'Email settings',
  connectEmailAccount: 'Connect your email',
  reconnectEmailAccount: 'Reconnect email account',
  disconnectEmailAccount: 'Disconnect email account',
  theConnectionWasASuccess: 'The connection was a success!',
  theConnectionWasASuccessDescription:
    'Your email account has been successfully connected to the Robin platform. You will now send your emails through Robin.',
  backToRobin: 'Back to Robin',
  emailConnectedDescription:
    'Your email account is connected to Robin. You can now send, receive, and track emails from our platform.',
  emailNotConnectedDescription: (
    <span>
      Connect your company email to Robin to increase conversion on messages.{' '}
      <a href="https://help.recruitrobin.com/en/articles/9414745-email" target="_blank" rel="noreferrer">
        Learn more
      </a>
    </span>
  ),

  emailConnections: 'Connected emails',
  disconnectEmailAccountModalDescription:
    'Are you sure you want to disconnect your email account from the Robin platform? If you proceed, you will no longer be able to send emails through Robin.',
  connectEmailModalDescription:
    'Connect your personal or company email accounts to Robin to send emails from our platform.',
  theConnectionFailed: 'Connection unsuccessful!',
  theConnectionFailedDescription:
    'Please ensure that the email you are connecting is the same as the one you use to login to Robin.',
  connectEmailDescription: (
    <span>
      To improve conversion on emails send we encourage everyone to connect their business email to Robin.{' '}
      <a href="https://help.recruitrobin.com/en/articles/9414745-email" target="_blank" rel="noreferrer">
        Learn more
      </a>
    </span>
  ),
  remindToConnectEmail: (name) => (
    <span>
      Hi {name},
      <br />
      <br />
      To provide you the following benefits, we're changing our email server;
      <br />
      <br />
      <div css={{ paddingLeft: '1em' }}>
        1. <strong>More candidates that respond to your email</strong>. You can now use your own email server to send
        emails instead of ours. This will reduce the number of emails that go into spam.
      </div>
      <br />
      <div css={{ paddingLeft: '1em' }}>
        2. <strong>Find sent emails in your mailbox</strong>. Every email you send will now be shown in your 'sent'
        mailbox of your own email account. That's how you can be sure that every email is sent correctly. As a bonus,
        you'll automatically collect all email communication in your own mailbox.
      </div>
      <br />
      <strong>1 minute</strong>
      <br />
      You can connect your email within one minute (only five clicks). Our email service will be running until the{' '}
      <strong>31st of July</strong>.
      <br />
      <br />
      <strong>Need help?</strong>
      <br />
      Ask us for help using the chat and we'll connect it together!
    </span>
  ),
  remindToReconnectEmail: (name) => (
    <span>
      Hi {name},
      <br />
      <br />
      Due to a technical issue, we need to reconnect your email account.
      <br />
      This is required to ensure you can continue using the email integration to send emails to candidates.
      <br />
      <br />
      Just click on the "Reconnect email" button below, and we will take you through the flow in just a minute!
    </span>
  ),
  werknlProfiles: 'Werknl Profiles',
  waitForTheCampaignToFullyLoad: 'Please wait for the search to be fully loaded',
  werknlIsCurrentlyDisabled: 'Werk.nl is currently disabled',
  loadingCandidateCv: 'Loading candidate CV',
  noShortlistedCandidate: ({ onClick }) => {
    return (
      <span>
        You haven't shortlisted any candidates yet! Start by shortlisting the best candidates from your{' '}
        <u onClick={onClick} css={{ cursor: 'pointer' }}>
          search results.
        </u>
      </span>
    );
  },
  noOtherStatusCandidate: (status) => `You haven't ${status} any candidates yet!`,
  werknlCandidateInfoPresentation: (
    <span>
      Which{' '}
      <a href={'https://www.werk.nl/werkzoekenden/'} target="_blank" rel="noreferrer">
        Werk.nl
      </a>{' '}
      profile do you prefer?
    </span>
  ),
  werknlCandidateInfoPresentationDescription:
    'We have changed the candidate profile layout to make it easier to assess candidates. Which version do you want to continue using?',
  werknlCandidateInfoPresentationNote: '*The choice can still be changed later in your personal profile settings',
  old: 'Old',
  confirmSelection: 'Confirm selection',
  werknlLayout: 'Werk.nl layout',
  cvFormatIssues: 'This CV cannot be displayed due to formatting issues.',
  disconnectVacancy: 'Disconnect from ATS',
  clickToActivatePlatform: (platform) => `Click to activate ${platform}`,
};
