import { OutreachEditorType } from '@recruitrobin/robin-theme/web-components';
import { OutreachTemplateType } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';
import { OutreachInformationWhyNotLabel } from 'shared/contexts/CandidateContext/types';

export type CandidateOutreachButtonOption = {
  key: 'call' | OutreachTemplateType;
  label: string;
  icon: React.ReactNode;
  disabled: boolean;
  tooltip?: OutreachInformationWhyNotLabel;
};

export const OutreachTemplateToEditorType: Record<string, OutreachEditorType> = {
  Call: OutreachEditorType.Phone,
  Email: OutreachEditorType.Email,
  Indeed: OutreachEditorType.Indeed,
  WhatsApp: OutreachEditorType.Whatsapp,
  LinkedIn: OutreachEditorType.Linkedin,
  'LinkedIn InMail': OutreachEditorType.Linkedin,
  'Werk.nl': OutreachEditorType.Werknl,
};
