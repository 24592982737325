import { createContext, useEffect, useState } from 'react';
import { dictionary as en } from './languages/en';
import { dictionary as nl } from './languages/nl';
import { ContextProps, LocalizationContextProviderProps, SupportedLanguage, SupportedLocale } from './types';

const defaultContext: ContextProps = {
  language: window.navigator.language.startsWith('en') || window.location.href.includes('localhost:6006') ? 'en' : 'nl',
  locale:
    window.navigator.language.startsWith('en') || window.location.href.includes('localhost:6006') ? 'en-US' : 'nl-NL',
  dictionary: window.navigator.language.startsWith('en') || window.location.href.includes('localhost:6006') ? en : nl,
  setLanguage: () => null,
};

export const LocalizationContext = createContext(defaultContext);

export const LocalizationContextProvider = ({ children }: LocalizationContextProviderProps) => {
  const [language, setLanguage] = useState<SupportedLanguage>(
    (localStorage.getItem('languageV2') as SupportedLanguage) || defaultContext.language,
  );
  const dictionaries = { nl, en };
  const dictionary = dictionaries[language as SupportedLanguage];

  const locale: SupportedLocale = language === 'en' ? 'en-US' : 'nl-NL';

  useEffect(() => {
    localStorage.setItem('languageV2', language);
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        locale,
        language,
        dictionary,
        setLanguage,
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};
