import DOMPurify from 'dompurify';

export const sanitizeEmailHtml = (htmlContent: string) =>
  DOMPurify.sanitize(htmlContent, {
    FORBID_TAGS: ['script'],
    FORBID_ATTR: [
      'onclick',
      'ondblclick',
      'onmousedown',
      'onmouseup',
      'onmouseover',
      'onmousemove',
      'onmouseout',
      'onmouseenter',
      'onmouseleave',
      'onkeydown',
      'onkeypress',
      'onkeyup',
      'onload',
      'onunload',
      'onresize',
      'onscroll',
      'onbeforeunload',
      'onhashchange',
      'onfocus',
      'onblur',
      'onchange',
      'oninput',
      'onsubmit',
      'onreset',
      'onselect',
      'oninvalid',
      'ontouchstart',
      'ontouchmove',
      'ontouchend',
      'ontouchcancel',
      'ondrag',
      'ondragstart',
      'ondragend',
      'ondragenter',
      'ondragover',
      'ondragleave',
      'ondrop',
      'onplay',
      'onpause',
      'onplaying',
      'onended',
      'onvolumechange',
      'onwaiting',
      'oncanplay',
      'oncanplaythrough',
      'onseeking',
      'onseeked',
      'onstalled',
      'onfocusin',
      'onfocusout',
      'oncontextmenu',
      'onwheel',
      'oncopy',
      'oncut',
      'onpaste',
      'oninput',
      'onpropertychange',
    ],
  });
