import { BaseClient } from 'clients/BaseClient/BaseClient';

import { hosts } from 'config';

import { AxiosResponse } from 'axios';
import { CandidateStatus } from 'services/candidates';
import {
  CampaignCandidateResponse,
  CampaignFiltersBody,
  CampaignResponse,
  ChangeOwnerHandler,
  CopyCampaignTemplatesHandler,
  CreateAtsSearchHandler,
  CreateCampaignHandler,
  CreateTextSearchHandler,
  DeleteCampaignCandidateNotesHandler,
  DownloadCampaignCandidateCVHandler,
  GetCampaignCandidateCVHandler,
  GetCampaignCandidateCVURLHandler,
  GetCampaignCandidateHandler,
  GetCampaignCandidateNotesHandler,
  GetCampaignCandidatesStatsHandler,
  GetCampaignHandler,
  GetCampaignRatedCandidatesHandler,
  GetCampaignsHandler,
  GetCampaignsOverviewHandler,
  GetCampaignsPageHandler,
  GetCampaignTemplatesHandler,
  GetCandidateActionsHandler,
  GetCandidateActivityLogHandler,
  GetCandidateHistoryHandler,
  GetCountCampaignsCandidatesHandler,
  GetCountCampaignsHandler,
  GetDistinctedLabelsHandler,
  GetFacetsHandler,
  GetFileHandler,
  GetInitialCampaignHandler,
  GetSearchResultsHandler,
  NotifyCandidateHandler,
  PostApproveCampaignCandidateHandler,
  PostCampaignCandidateNotesHandler,
  PutCampaignCandidateNotesHandler,
  PutCampaignCandidateSaveLabelsHandler,
  UpdateCampaignHandler,
} from './CampaignsClient.types';

export class CampaignsClient extends BaseClient {
  constructor() {
    super();
    this.getFacets = this.getFacets.bind(this);
    this.createOrUpdateCampaignCandidate = this.createOrUpdateCampaignCandidate.bind(this);
    this.createCampaign = this.createCampaign.bind(this);
    this.updateCampaign = this.updateCampaign.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.createCampaignFilters = this.createCampaignFilters.bind(this);
    this.updateCampaignFilters = this.updateCampaignFilters.bind(this);
    this.getCampaignFilters = this.getCampaignFilters.bind(this);
    this.getCampaignCurrentFilters = this.getCampaignCurrentFilters.bind(this);
    this.getCampaignCandidate = this.getCampaignCandidate.bind(this);
  }

  changeOwner: ChangeOwnerHandler = ({ campaignId, userId }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/change_owner/`,
      method: 'POST',
      data: {
        user_id: userId,
      },
    });
  };

  getCampaigns: GetCampaignsHandler = (params) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/`,
      method: 'GET',
      params,
    });
  };

  getCountCampaigns: GetCountCampaignsHandler = (params) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/count_campaigns/`,
      method: 'GET',
      params,
    });
  };

  get20Campaigns: GetCampaignsPageHandler = (params) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/?limit=20`,
      method: 'GET',
      params,
    });
  };

  getCampaignsPage: GetCampaignsPageHandler = (params) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/`,
      method: 'GET',
      params,
    });
  };

  notifyCandidate: NotifyCandidateHandler = ({ campaign_id, candidate_id, send_method, data }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaign_id}/candidates/${candidate_id}/notifications/${send_method}/`,
      method: 'POST',
      data,
    });
  };

  getFacets: GetFacetsHandler = (id) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${id}/search_facets/`,
      method: 'GET',
    });
  };

  getCampaignsOverview: GetCampaignsOverviewHandler = (params) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/campaigns_overview`,
      params,
      method: 'GET',
    });
  };

  getCampaignRatedCandidate: GetCampaignRatedCandidatesHandler = ({
    id,
    status,
    limit = 20,
    offset = 0,
    labels = [],
    sort: { by = 'updated_at', order = 'ASC' } = {},
    cancelToken,
  }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${id}/candidates/`,
      method: 'GET',
      params: {
        limit,
        offset,
        status,
        ordering: `${order === 'DESC' ? '-' : ''}${by}`,
        reasons: labels,
      },
      cancelToken,
    });
  };

  getCampaignCandidate: GetCampaignCandidateHandler = ({ campaignId, candidateId }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/${candidateId}/`,
      method: 'GET',
    });
  };

  getCountCampaignsCandidates: GetCountCampaignsCandidatesHandler = () => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/count_initial_candidates/`,
      method: 'GET',
    });
  };

  getCampaignCandidatesStats: GetCampaignCandidatesStatsHandler = (id) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${id}/candidates_stats/`,
      method: 'GET',
    });
  };

  async createOrUpdateCampaignCandidate({
    campaignId,
    esPersonId,
    status,
    reasons,
    is_unlocked,
    freeUnlock,
  }: {
    campaignId: string | number;
    esPersonId: string | number;
    status: CandidateStatus;
    reasons?: string[];
    is_unlocked?: boolean;
    freeUnlock?: boolean;
  }): Promise<AxiosResponse<CampaignCandidateResponse>> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/`,
      method: 'POST',
      data: {
        es_person_id: esPersonId,
        is_unlocked,
        status,
        reasons,
        ...(typeof freeUnlock !== undefined && { free_unlock: freeUnlock }),
      },
    });
  }

  getCampaign: GetCampaignHandler = (campaignId) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/`,
      method: 'GET',
    });
  };

  getInitialCampaign: GetInitialCampaignHandler = () => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/initial_campaign/`,
      method: 'GET',
    });
  };

  createCampaign: CreateCampaignHandler = (data) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/`,
      method: 'POST',
      data,
    });
  };

  updateCampaign: UpdateCampaignHandler = (campaignId, data) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/`,
      method: 'PATCH',
      data,
    });
  };

  async copyCampaign(campaignId: string | number): Promise<AxiosResponse<CampaignResponse>> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/copy/`,
      method: 'POST',
    });
  }

  async deleteCampaign(campaignId: string | number) {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/`,
      method: 'DELETE',
    });
  }

  async createCampaignFilters(
    campaignId: string,
    data: CampaignFiltersBody,
  ): Promise<AxiosResponse<CampaignFiltersBody>> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/filters/`,
      method: 'POST',
      data,
    });
  }

  async updateCampaignFilters(
    campaignId: string | number,
    filtersId: string | number,
    data: CampaignFiltersBody,
  ): Promise<AxiosResponse<CampaignFiltersBody>> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/filters/${filtersId}/`,
      method: 'PUT',
      data,
    });
  }

  async getCampaignFilters(
    key: string,
    campaignId: string | number,
    filtersId: string | number,
  ): Promise<AxiosResponse<CampaignFiltersBody>> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/filters/${filtersId}/`,
      method: 'GET',
    });
  }

  async getCampaignCurrentFilters(key: string, campaignId: string): Promise<AxiosResponse<CampaignFiltersBody>> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/filters/current/`,
      method: 'GET',
    });
  }

  getSearchResults: GetSearchResultsHandler = ({ campaignId, limit = 20, offset = 0, cancelToken }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/search_batch/`,
      method: 'POST',
      params: { limit, offset },
      cancelToken,
    });
  };

  getCandidateActivityLog: GetCandidateActivityLogHandler = ({ candidate_id, limit = 10, offset = 0 }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/candidates/${candidate_id}/activity_logs?limit=${limit}&offset=${offset}`,
      method: 'GET',
    });
  };

  getCandidateHistory: GetCandidateHistoryHandler = ({ candidate_id }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/candidates/${candidate_id}/history`,
      method: 'GET',
    });
  };

  getCampaignCandidateCV: GetCampaignCandidateCVHandler = ({ campaignId, candidateId, options }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/${candidateId}/cv_preview/`,
      method: 'GET',
      ...options,
    });
  };

  getFile: GetFileHandler = (url) => {
    return this.request({ url, method: 'GET', responseType: 'blob' });
  };

  getCampaignCandidateCVURL: GetCampaignCandidateCVURLHandler = ({ cvFileId, shouldUseRedact = false }) => {
    return this.request({
      url: `${hosts.cvApi()}/preview/${shouldUseRedact ? 'cv_url_redact' : 'cv_url'}/${cvFileId}/`,
      method: 'GET',
    });
  };

  getCampaignCandidateATSCV: GetCampaignCandidateCVHandler = ({ campaignId, candidateId, options }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/${candidateId}/cv_preview_ats/`,
      method: 'GET',
      ...options,
    });
  };

  downloadCampaignCandidateCV: DownloadCampaignCandidateCVHandler = (campaignId, candidateId, name) => {
    return this.fetchFile(`${name || 'CV'}.pdf`, {
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/${candidateId}/cv_preview/`,
      method: 'GET',
    });
  };

  postApproveCampaignCandidate: PostApproveCampaignCandidateHandler = ({ campaignId, candidateId }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/${candidateId}/post_approve_candidate/`,
      method: 'POST',
    });
  };

  async postRefreshCampaignCandidate(campaignId: string | number, esPersonId: number | string): Promise<any> {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaignId}/candidates/${esPersonId}/refresh_candidate/`,
      method: 'POST',
    });
  }

  getCampaignCandidateNotes: GetCampaignCandidateNotesHandler = ({ es_person_id }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/candidates/${es_person_id}/notes/`,
      method: 'GET',
    });
  };

  postCampaignCandidateNotes: PostCampaignCandidateNotesHandler = ({ es_person_id, notes, campaign_id }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/candidates/${es_person_id}/notes/`,
      data: { content: notes, campaign: campaign_id },
      method: 'POST',
    });
  };

  putCampaignCandidateNotes: PutCampaignCandidateNotesHandler = ({ es_person_id, note_id, notes, campaign_id }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/candidates/${es_person_id}/notes/${note_id}/`,
      data: { content: notes, campaign: campaign_id },
      method: 'PUT',
    });
  };

  deleteCampaignCandidateNotes: DeleteCampaignCandidateNotesHandler = ({ es_person_id, note_id }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/candidates/${es_person_id}/notes/${note_id}/`,
      method: 'DELETE',
    });
  };

  putCampaignCandidateLabels: PutCampaignCandidateSaveLabelsHandler = ({ es_person_id, campaign_id, labels }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/${campaign_id}/candidates/${es_person_id}/labels/`,
      data: { reasons: labels },
      method: 'PUT',
    });
  };

  getDistinctedLabels: GetDistinctedLabelsHandler = () => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/candidates/labels/`,
      method: 'GET',
    });
  };

  getCampaignTemplates: GetCampaignTemplatesHandler = ({ search, limit = 200 }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaign_templates/?limit=${limit}&search=${search}`,
      method: 'GET',
    });
  };

  copyCampaignTemplates: CopyCampaignTemplatesHandler = ({ templateId }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaign_templates/${templateId}/copy/`,
      method: 'POST',
    });
  };

  getCandidateActions: GetCandidateActionsHandler = () => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/candidates/status/actions/`,
      method: 'GET',
    });
  };

  createTextSearch: CreateTextSearchHandler = ({ job_description }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/create_through_text/`,
      method: 'POST',
      data: { job_description },
    });
  };

  createAtsSearch: CreateAtsSearchHandler = ({ ats_id }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/create_through_ats_vacancy/`,
      method: 'POST',
      data: { ats_id },
    });
  };
}

export const campaignsClient = new CampaignsClient();
