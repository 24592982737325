import type { default as Candidate } from 'model/Candidate';
import { UseActionsHookReturnType } from 'shared/contexts/CandidateContext/types';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { CandidateStatus } from 'types/candidate';

export enum HistoryStatus {
  Shortlisted = 'shortlisted',
  Hidden = 'hidden',
  Matched = 'matched',
  Linkedin = 'linkedin',
  Intake = 'intake',
  LabelAdded = 'labelAdded',
  Contacted = 'contacted',
  Invisible = 'invisible',
  Unlocked = 'unlocked',
  SentToAts = 'sentToAts',
  Call = 'call',
  EmailSent = 'email',
  EmailReceived = 'emailReceived',
  IndeedSent = 'indeed',
  WerknlSent = 'werknl',
  WhatsappSent = 'whatsapp',
  LinkedinConnection = 'linkedinConnection',
  LinkedinInmail = 'linkedin_inmail',
  Note = 'note',
  InterviewPlannedOtys = 'interviewPlannedOtys',
  InterviewPlannedRecruitNow = 'interviewPlannedRecruitNow',
  InterviewPlannedBullhorn = 'interviewPlannedBullhorn',
}

export enum HistoryContactMethod {
  linkedin = HistoryStatus.Linkedin,
  linkedin_inmail = HistoryStatus.LinkedinInmail,
  whatsapp = HistoryStatus.WhatsappSent,
  email = HistoryStatus.EmailSent,
  emailReceived = HistoryStatus.EmailReceived,
  indeed = HistoryStatus.IndeedSent,
  call = HistoryStatus.Call,
  werknl = HistoryStatus.WerknlSent,
}

export const isHistoryContactMethod = (type: unknown): type is HistoryContactMethod => {
  return Object.values(HistoryContactMethod).some((key) => key === type);
};

export enum CandidateDetailsTab {
  CV = 'cv',
  details = 'details',
  activity = 'activity',
}

export type CandidateDetailsConfig = {
  initialTab: CandidateDetailsTab;
};

export type CandidateConfig = {
  candidateDetails: CandidateDetailsConfig;
};

export type SearchV3CandidateUIState = {
  isSelected: boolean;
  isLoading: boolean;
  status: CandidateStatus;
};

export type SearchV3CandidateResponse = Pick<Candidate, 'key' | 'name' | 'notes' | 'es_person_id'> & {
  select: (config?: DeepPartial<CandidateConfig>) => void;
  bindMatchesAndGetJobboards: (matches: CandidateMatches) => CandidateJobboardsWithActiveState[];

  experiences: CandidateExperience[];
  preferences: CandidatePreferences[];
  location?: CandidateLocation;
  lastRefresh?: string;
  history: CandidateActivityHistory[];
};

export type SearchV3CandidateActivityLog = {
  data: CandidateActivityHistory[];
  isLoading: boolean;
};

export type SearchV3CandidateStatefulResponse = {
  config: CandidateConfig;
  candidateUIState: SearchV3CandidateUIState;
  candidate: SearchV3CandidateResponse;
  candidateActivityLog: SearchV3CandidateActivityLog;
};

export type Searchv3CandidateHandler = (
  candidate: Candidate | undefined,
  config: { actions: UseActionsHookReturnType; config?: CandidateConfig },
) => SearchV3CandidateStatefulResponse;

/**
 * Types mapped for frontend use
 */

export type CandidateLocation = { name: string; distance?: string };

export type CandidatePreferences = { id: number; text: string; meta: string };

export type CandidateExperience = {
  position: string;
  company?: string;
  date: string;
  description?: string;
  timeInMonths?: string;
};

export type CandidateMatches = Record<string, string[] | undefined>;

export type CandidateJobboardsWithActiveState = [CampaignJobboard, boolean];

export type CandidateActivityHistory = {
  id: number;
  campaignName?: string;
  type: HistoryStatus;
  contactSendMethod?: HistoryContactMethod;
  by: string;
  createdAt: Date;
  timeAgo?: string;
  byId?: number;
  content?: {
    subject?: string;
    message?: string;
    signoff?: string;
  };
  isNewMessage?: boolean;
};
