import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { CampaignOverviewResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { useGTM } from 'hooks/gtm';
import { useCallback, useMemo, useState } from 'react';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { ConfigContext } from 'shared/contexts/ConfigContext/ConfigContext';
import { useContextSelector } from 'use-context-selector';
import { IsLoadingAction, UseContainerHandler } from './CampaignListItem.types';
import { CandidateStatus } from 'services/candidates';

export const useContainer: UseContainerHandler = ({ data, index }) => {
  const [isLoadingAction, _setIsLoadingAction] = useState<IsLoadingAction>({
    changeOwner: false,
    duplicate: false,
    toggleStatus: false,
    delete: false,
  });
  const campaign = data[index] as CampaignOverviewResponse | undefined;
  const selectedCampaign = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign);
  const campaignManager = useContextSelector(CampaignManagerContext, (state) => state.campaignManager);
  const { status } = useContextSelector(CampaignManagerContext, (state) => state.filters);
  const isIframe = useContextSelector(ConfigContext, (state) => state.isIframe);
  const gtm = useGTM();

  const setIsLoadingAction = useCallback((action: keyof IsLoadingAction, stateOrCallback: boolean | Promise<void>) => {
    const setState = (isLoading: boolean) => _setIsLoadingAction((state) => ({ ...state, [action]: isLoading }));

    if (typeof stateOrCallback === 'boolean') {
      setState(stateOrCallback);
    } else {
      setState(true);
      stateOrCallback.finally(() => setState(false));
    }
  }, []);

  const actionableCampaign = useMemo(() => {
    if (campaign) {
      const actionableCampaign = campaignManager(campaign);

      return {
        select: (initialTab?: CandidateStatus) => {
          if (isIframe) {
            const isSearchCampaignsRoute = location.pathname.includes('/search/campaigns');

            if (isSearchCampaignsRoute) {
              browserExtensionClient.sendMessage('openCampaign', { campaignId: campaign.id });
            }
          }

          gtm.openCampaign(campaign.id);
          return actionableCampaign.select(initialTab);
        },
        duplicate: () => setIsLoadingAction('duplicate', actionableCampaign.duplicate()),
        toggleStatus: () => setIsLoadingAction('toggleStatus', actionableCampaign.toggleStatus()),
        delete: () => setIsLoadingAction('delete', actionableCampaign.delete()),
        changeOwner: (campaignId: number, userId: number) => {
          gtm.changeCampaignOwner(campaign.id);
          return setIsLoadingAction('changeOwner', actionableCampaign.changeOwner(campaignId, userId));
        },
      };
    }

    return {
      select: () => {},
      duplicate: () => {},
      toggleStatus: () => {},
      delete: () => {},
      changeOwner: () => {},
    };
  }, [campaign, campaignManager, isIframe]);

  return <ReturnType<UseContainerHandler>>{
    isLoading: campaign === undefined,
    campaign,
    handleOpenCampaign: (initialTab) => actionableCampaign.select(initialTab),
    handleChangeOnwer: actionableCampaign.changeOwner,
    handleDuplicate: actionableCampaign.duplicate,
    handleToggleStatus: actionableCampaign.toggleStatus,
    handleDelete: actionableCampaign.delete,
    status,
    isLoadingAction,
    isSelected: campaign?.id === selectedCampaign?.id && campaign !== undefined && selectedCampaign !== undefined,
  };
};
