import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CandidateActivityLog, CandidateHistory } from 'clients/CampaignsClient/CampaignsClient.types';
import { default as Candidate } from 'model/Candidate/Candidate';
import { useMemo } from 'react';
import { QueryKey } from 'types/query';
import { extractLastRefresh } from './extractLastRefresh';
import generateLabelsCacheKey from './generateLabelsCacheKey';
import { CandidateActivityHistory, HistoryStatus, SearchV3CandidateActivityLog } from './types';
import { sanitizeEmailHtml } from 'utils/sanitizeEmailHtml';

export const useCandidateHistory = (candidate: Candidate | undefined, language: string) => {
  const { data, isFetching } = useQuery<CandidateHistory[]>(
    [
      QueryKey.candidateHistory,
      {
        candidateEsPersonId: candidate?.es_person_id,
        status: candidate?.status,
        labelsKey: generateLabelsCacheKey(candidate?.reasons || []),
      },
    ],
    async () => {
      if (!candidate) throw Error('Candidate not defined');

      const response = await campaignsClient.getCandidateHistory({
        candidate_id: candidate.es_person_id,
      });

      return response.data;
    },
    { enabled: !!candidate, cacheTime: 0 },
  );

  const history = useMemo<CandidateActivityHistory[]>(() => {
    const results = data || [];

    const resultsMapped = results
      .map((history) => {
        const {
          id,
          action,
          date,
          campaign: { name },
          candidate_notification,
          subject: hSubject,
          content,
        } = history;
        const type = getActionAsCandidateHistoryStatus(action);

        if ([HistoryStatus.Invisible, HistoryStatus.SentToAts, HistoryStatus.Unlocked].includes(type)) return undefined;

        const contactSendMethod = candidate_notification?.send_method;
        const by = history.user.first_name;
        const subject = sanitizeEmailHtml(candidate_notification?.subject || hSubject);
        const message = sanitizeEmailHtml(candidate_notification?.message || content);
        const signoff = sanitizeEmailHtml(candidate_notification?.sign_off || '');

        const hasMessage = subject || message || signoff;

        return {
          id,
          type,
          by,
          createdAt: new Date(date),
          campaignName: name,
          timeAgo: extractLastRefresh(date, language),
          contactSendMethod,
          byId: history.user.id,
          content: hasMessage ? { message, signoff, subject } : undefined,
        } satisfies CandidateActivityHistory;
      })
      .filter(Boolean);

    return resultsMapped as CandidateActivityHistory[];
  }, [data]);

  return useMemo<SearchV3CandidateActivityLog>(() => ({ data: history, isLoading: isFetching }), [history, isFetching]);
};

export const getActionAsCandidateHistoryStatus = (action: CandidateActivityLog['action']): HistoryStatus => {
  switch (action) {
    case 'CAMPAIGN_CANDIDATE_CONTACTED':
      return HistoryStatus.Contacted;
    case 'CAMPAIGN_CANDIDATE_HIDDEN':
      return HistoryStatus.Hidden;
    case 'CAMPAIGN_CANDIDATE_INVISIBLE':
      return HistoryStatus.Invisible;
    case 'CAMPAIGN_CANDIDATE_LABEL_CHANGED':
      return HistoryStatus.LabelAdded;
    case 'CAMPAIGN_CANDIDATE_MATCHED':
      return HistoryStatus.Matched;
    case 'CAMPAIGN_CANDIDATE_SENT_TO_ATS':
      return HistoryStatus.SentToAts;
    case 'CAMPAIGN_CANDIDATE_SHORTLISTED':
      return HistoryStatus.Shortlisted;
    case 'CAMPAIGN_CANDIDATE_UNLOCKED':
      return HistoryStatus.Unlocked;
    case 'CAMPAIGN_CANDIDATE_NOTE':
      return HistoryStatus.Note;
  }
};

export const getActivityLogAuthorIfNecessary = (activityLog: CandidateActivityLog): string | undefined => {
  return activityLog.user.first_name;
};
